<template>
  <v-row justify="center">
    <v-col cols="12" lg="8">
      <Aggregate
        title="Generators"
        v-model="input.generators"
        :error-messages="$utils.getIfExists(errors, ['input', 'generators'])"
        :ItemConstructor="generatorFactory"
        :headers="headers"
        v-bind="$attrs"
        @change="$emit('change')"
      >
        <template #item.type="{ item, errors, allowEdit }">
          <v-select
            v-model="item.type"
            :items="types"
            :error-messages="$utils.getIfExists(errors, ['type', 'messages'])"
            :disabled="!allowEdit || item.noDelete"
            label="Generator Type"
            single-line
            @change="$emit('change')"
          ></v-select>
        </template>
        <template #item.source="{ item, errors, allowEdit }">
          <span v-if="item.type === 'HYDRO'">Hydro</span>
          <v-select
            v-else
            v-model="item.source"
            :items="sources[item.type]"
            :error-messages="$utils.getIfExists(errors, ['source', 'messages'])"
            :disabled="!allowEdit || item.noDelete"
            label="Source"
            single-line
            @change="sourceChange($event, item.id)"
          ></v-select>
        </template>
        <template #item.capacity="{ item }"> {{ item.capacity[0] }} MW </template>
        <template #default="{ item, errors }">
          <v-card class="mb-3">
            <v-card-title>
              <h6 class="text-h6">General</h6>
            </v-card-title>
            <v-card-text>
              <v-switch
                v-model="item.active"
                :label="hydroSchema.active.title"
                :hint="hydroSchema.active.tooltip"
                persistent-hint
                :error-messages="$utils.getIfExists(errors, ['active'])"
                :class="`my-${inputMargin}`"
                @change="$emit('change')"
              ></v-switch>
              <v-text-field
                v-model.number="item.technical_life"
                :label="hydroSchema.technical_life.title"
                :hint="hydroSchema.technical_life.tooltip"
                :error-messages="$utils.getIfExists(errors, ['technical_life', 'messages'])"
                suffix="years"
                type="number"
                @change="$emit('change')"
              ></v-text-field>
              <InputToggleParam
                v-model="item.operating_status"
                :label="hydroSchema.operating_status.properties.active.title"
                :hint="hydroSchema.operating_status.properties.active.tooltip"
                :error-messages="$utils.getIfExists(errors, ['operating_status'])"
                @change="$emit('change')"
              >
                <template v-slot="{ disabled, param }">
                  <InputProfile
                    v-model="param.id"
                    :hint="hydroSchema.operating_status.then.properties.param.properties.id.tooltip"
                    profile-type="DAILY_OPERATING_STATUS"
                    hide-divider
                    :disabled="disabled"
                    @change="$emit('change')"
                  ></InputProfile>
                </template>
                <!-- <template #below="{param, disabled}"></template> -->
              </InputToggleParam>
              <v-text-field
                v-model.number="item.start_year"
                :label="hydroSchema.start_year.title"
                :hint="hydroSchema.start_year.tooltip"
                :error-messages="$utils.getIfExists(errors, ['start_year', 'messages'])"
                type="number"
                @change="$emit('change')"
              ></v-text-field>

              <template v-if="item.type == 'THERMAL'">
                <InputToggleParam
                  v-model="item.base_profile"
                  :label="thermalSchema.base_profile.properties.active.title"
                  :hint="thermalSchema.base_profile.properties.active.tooltip"
                  :error-messages="$utils.getIfExists(errors, ['base_profile'])"
                  @change="$emit('change')"
                >
                  <template #below="{ param, disabled, errors }">
                    <v-col class="grow">
                      <InputYearlyProfile
                        v-model="param.profiles"
                        :hint="
                          thermalSchema.base_profile.then.properties.param.properties.profiles
                            .tooltip
                        "
                        :error-messages="$utils.getIfExists(errors, ['profiles', 'messages'])"
                        :num_years="num_years"
                        :profile-type="item.type"
                        :disabled="disabled"
                        @change="$emit('change')"
                      ></InputYearlyProfile>
                    </v-col>
                  </template>
                </InputToggleParam>

                <v-row>
                  <v-col v-if="item.source !== 'GEOTHERMAL'" cols="6" md="4" class="px-1">
                    <v-text-field
                      v-model.number="item.heat_rate"
                      :label="thermalSchema.heat_rate.title"
                      :hint="thermalSchema.heat_rate.tooltip"
                      :error-messages="$utils.getIfExists(errors, ['heat_rate', 'messages'])"
                      suffix="GJ/MWh"
                      type="number"
                      @change="$emit('change')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="4" class="px-1">
                    <v-text-field
                      v-model.number="item.ramp_rate"
                      :label="thermalSchema.ramp_rate.title"
                      :hint="thermalSchema.ramp_rate.tooltip"
                      :error-messages="$utils.getIfExists(errors, ['ramp_rate', 'messages'])"
                      suffix="MW/h"
                      type="number"
                      @change="$emit('change')"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="item.source !== 'GEOTHERMAL'" cols="12" md="4" class="px-1">
                    <v-text-field
                      v-model.number="item.co2_rate"
                      :label="thermalSchema.co2_rate.title"
                      :hint="thermalSchema.co2_rate.tooltip"
                      :error-messages="$utils.getIfExists(errors, ['co2_rate', 'messages'])"
                      suffix="Tonne/GJ"
                      type="number"
                      @change="$emit('change')"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </template>

              <template v-if="item.type == 'RENEWABLE'">
                <!-- <v-select v-model="item.source" label="Source" :items="sources.RENEWABLE"></v-select> -->
                <InputYearlyProfile
                  v-model="item.profile"
                  :title="renewableSchema.profile.title"
                  :hint="renewableSchema.profile.tooltip"
                  :error-messages="$utils.getIfExists(errors, ['profile'])"
                  :num_years="num_years"
                  :profile-type="item.source"
                  :fill="['HYDRO_ROR', 'SOLAR_PV', 'WIND'].includes(item.source)"
                  :fill-value="item.profile[0] || null"
                  @change="$emit('change')"
                ></InputYearlyProfile>
                <InputToggleParam
                  v-model="item.joint_ramping"
                  :error-messages="$utils.getIfExists(errors, ['joint_ramping'])"
                  label="Renewable + Storage Ramping Constraint"
                  @change="$emit('change')"
                >
                  <template v-slot="{ disabled, param, errors }">
                    <v-text-field
                      v-model.number="param.ramp_rate"
                      :error-messages="$utils.getIfExists(errors, ['ramp_rate', 'messages'])"
                      :disabled="disabled"
                      label="Ramp Rate"
                      suffix="%/h"
                      type="number"
                      @change="$emit('change')"
                    ></v-text-field>
                  </template>
                  <template #below="{ param, disabled, errors }">
                    <v-autocomplete
                      v-model="param.storages"
                      :error-messages="$utils.getIfExists(errors, ['storages', 'messages'])"
                      label="Energy Storage Units"
                      :items="input.storage"
                      :disabled="disabled"
                      chips
                      multiple
                      deletable-chips
                      item-text="name"
                      item-value="id"
                      @change="$emit('change')"
                    ></v-autocomplete>
                  </template>
                </InputToggleParam>
              </template>
            </v-card-text>
          </v-card>

          <v-card class="mb-3">
            <v-card-title>
              <h6 class="text-h6">Expansion</h6>
            </v-card-title>
            <v-card-text>
              <v-switch
                v-model="item.expansion_candidate"
                label="Expansion Candidate"
                :error-messages="$utils.getIfExists(errors, ['expansion_candidate'])"
                @change="expansionCandidateChange($event, item.id)"
              ></v-switch>
              <InputYearly
                v-if="item.expansion_candidate"
                v-model="item.investment"
                :label="hydroSchema.investment.title"
                :hint="hydroSchema.investment.tooltip"
                :error-messages="$utils.getIfExists(errors, ['investment'])"
                suffix="$/KW"
                :num_years="num_years"
                type="number"
                @change="$emit('change')"
                fill
                :fill-value="item.type == 'THERMAL' ? 8000 : hydroSchema.investment.items.default"
              ></InputYearly>
              <InputYearly
                v-if="item.expansion_candidate"
                v-model="item.maximum_build"
                :label="hydroSchema.maximum_build.title"
                :hint="hydroSchema.maximum_build.tooltip"
                :error-messages="$utils.getIfExists(errors, ['maximum_build'])"
                suffix="MW"
                type="number"
                :num_years="num_years"
                @change="$emit('change')"
                fill
                :fill-value="hydroSchema.maximum_build.items.default"
              ></InputYearly>
            </v-card-text>
          </v-card>

          <v-card class="mb-3">
            <v-card-title>
              <h6 class="text-h6">Metrics</h6>
            </v-card-title>
            <v-card-text>
              <v-switch
                v-model="item.LCOE_Calculation"
                :label="hydroSchema.LCOE_calculation.title"
                :hint="hydroSchema.LCOE_calculation.tooltip"
                persistent-hint
                :error-messages="$utils.getIfExists(errors, ['LCOE_Calculation'])"
                @change="$emit('change')"
              ></v-switch>
            </v-card-text>
          </v-card>

          <v-card class="mb-3">
            <v-card-title>
              <h6 class="text-h6">Financial</h6>
            </v-card-title>
            <v-card-text>
              <v-text-field
                v-model.number="item.economic_life"
                :label="hydroSchema.economic_life.title"
                :hint="hydroSchema.economic_life.tooltip"
                :error-messages="$utils.getIfExists(errors, ['economic_life', 'messages'])"
                suffix="years"
                type="number"
                :class="`my-${inputMargin}`"
                @change="$emit('change')"
              ></v-text-field>
              <v-text-field
                v-model.number="item.discount"
                :label="hydroSchema.discount.title"
                :hint="hydroSchema.discount.tooltip"
                :error-messages="$utils.getIfExists(errors, ['discount', 'messages'])"
                :placeholder="
                  input.discount && input.discount != null ? input.discount.toString() : ''
                "
                type="number"
                :class="`my-${inputMargin}`"
                @change="$emit('change')"
              ></v-text-field>
            </v-card-text>
          </v-card>

          <v-card class="mb-3">
            <v-card-title>
              <h6 class="text-h6">Costs</h6>
            </v-card-title>
            <v-card-text>
              <InputYearly
                v-model="item.vom"
                :label="hydroSchema.vom.title"
                :hint="hydroSchema.vom.tooltip"
                :error-messages="$utils.getIfExists(errors, ['vom'])"
                :num_years="num_years"
                suffix="$/MWh"
                type="number"
                @change="$emit('change')"
                fill
                :fill-value="hydroSchema.vom.items.default"
              ></InputYearly>
              <v-text-field
                v-model.number="item.fom"
                :label="hydroSchema.fom.title"
                :hint="hydroSchema.fom.tooltip"
                :error-messages="$utils.getIfExists(errors, ['fom', 'messages'])"
                suffix="$/KW-years"
                type="number"
                @change="$emit('change')"
              ></v-text-field>
              <template v-if="item.type == 'THERMAL' && item.source !== 'GEOTHERMAL'">
                <v-switch
                  v-model="item.profile_fuel_price"
                  :label="thermalSchema.profile_fuel_price.title"
                  :hint="thermalSchema.profile_fuel_price.tooltip"
                  persistent-hint
                  @change="$emit('change')"
                ></v-switch>
                <InputYearlyProfile
                  v-if="item.profile_fuel_price"
                  v-model="item.fuel_price_profiles"
                  :label="thermalSchema.fuel_price_profiles.title"
                  :hint="thermalSchema.fuel_price_profiles.tooltip"
                  :num_years="num_years"
                  :profile-type="thermalSchema.fuel_price_profiles.profileType"
                  @change="$emit('change')"
                ></InputYearlyProfile>
                <InputYearly
                  v-else
                  v-model="item.fuel_price"
                  :label="thermalSchema.fuel_price.title"
                  :hint="thermalSchema.fuel_price.tooltip"
                  :error-messages="$utils.getIfExists(errors, ['fuel_price'])"
                  :suffix="thermalSchema.fuel_unit.default"
                  :num_years="num_years"
                  type="number"
                  fill
                  :fill-value="thermalSchema.fuel_price.items.default"
                  @change="$emit('change')"
                >
                  <template #default="{ year, value, type, readonly, errorMessages }">
                    <v-text-field
                      v-model.number="value[year - 1]"
                      :error-messages="errorMessages"
                      :label="`Year ${year}`"
                      :type="type"
                      :readonly="readonly"
                      @change="$emit('change')"
                    >
                      <template #append-outer>
                        <v-select
                          v-model="item.fuel_unit"
                          :items="thermalSchema.fuel_unit.enum"
                          label="Unit"
                          class="ma-0 pa-0"
                          single-line
                          @change="$emit('change')"
                        ></v-select>
                      </template>
                    </v-text-field>
                  </template>
                </InputYearly>
              </template>
            </v-card-text>
          </v-card>

          <v-card class="mb-3">
            <v-card-title>
              <h6 class="text-h6">Capacity</h6>
            </v-card-title>
            <v-card-text>
              <template v-if="item.type == 'THERMAL'">
                <InputYearly
                  v-model="item.capacity"
                  :label="thermalSchema.capacity.title"
                  :hint="thermalSchema.capacity.tooltip"
                  :error-messages="$utils.getIfExists(errors, ['capacity'])"
                  suffix="MW"
                  :num_years="num_years"
                  type="number"
                  fill
                  :fill-value="thermalSchema.capacity.items.default"
                  @change="$emit('change')"
                ></InputYearly>
                <v-row>
                  <v-col cols="6" md="6" class="px-1">
                    <v-text-field
                      v-model.number="item.derate_summer"
                      :label="thermalSchema.derate_summer.title"
                      :hint="thermalSchema.derate_summer.tooltip"
                      :error-messages="$utils.getIfExists(errors, ['derate_summer', 'messages'])"
                      suffix="%"
                      type="number"
                      @change="$emit('change')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="6" class="px-1">
                    <v-text-field
                      v-model.number="item.derate_winter"
                      :label="thermalSchema.derate_winter.title"
                      :hint="thermalSchema.derate_winter.tooltip"
                      :error-messages="$utils.getIfExists(errors, ['derate_winter', 'messages'])"
                      suffix="%"
                      type="number"
                      @change="$emit('change')"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </template>
              <template v-if="item.type == 'RENEWABLE'">
                <InputYearly
                  v-model="item.capacity"
                  label="Minimum Installed Capacity"
                  :hint="renewableSchema.capacity.tooltip"
                  :error-messages="$utils.getIfExists(errors, ['capacity'])"
                  suffix="MW"
                  :num_years="num_years"
                  type="number"
                  @change="$emit('change')"
                  fill
                  :fill-value="renewableSchema.capacity.items.default"
                ></InputYearly>
                <v-text-field
                  v-model.number="item.firm"
                  label="Firm Power"
                  :hint="renewableSchema.firm.tooltip"
                  :error-messages="$utils.getIfExists(errors, ['firm', 'messages'])"
                  suffix="%"
                  type="number"
                  @change="$emit('change')"
                ></v-text-field>
              </template>
              <template v-if="item.type == 'HYDRO'">
                <InputYearly
                  v-model="item.capacity"
                  :label="hydroSchema.capacity.title"
                  :hint="hydroSchema.capacity.tooltip"
                  :error-messages="$utils.getIfExists(errors, ['capacity'])"
                  suffix="MW"
                  :num_years="num_years"
                  type="number"
                  fill
                  :fill-value="hydroSchema.capacity.items.default"
                  @change="$emit('change')"
                ></InputYearly>
                <v-text-field
                  v-model.number="item.firm"
                  :label="hydroSchema.firm.title"
                  :hint="hydroSchema.firm.tooltip"
                  :error-messages="$utils.getIfExists(errors, ['firm', 'messages'])"
                  suffix="%"
                  type="number"
                  :class="`my-${inputMargin}`"
                  @change="$emit('change')"
                ></v-text-field>
                <v-text-field
                  v-model.number="item.ramp_rate"
                  :label="hydroSchema.ramp_rate.title"
                  :hint="hydroSchema.ramp_rate.tooltip"
                  :error-messages="$utils.getIfExists(errors, ['ramp_rate', 'messages'])"
                  suffix="MW/h"
                  type="number"
                  :class="`my-${inputMargin}`"
                  @change="$emit('change')"
                ></v-text-field>
              </template>
            </v-card-text>
          </v-card>

          <v-card class="mb-3" v-if="['THERMAL', 'HYDRO'].includes(item.type)">
            <v-card-title>
              <h6 class="text-h6">Reserves</h6>
            </v-card-title>
            <v-card-text>
              <InputReserve
                v-model="item.reserves"
                :label="hydroSchema.reserves.title"
                :hint="hydroSchema.reserves.tooltip"
                :reserves="input.reserves"
                :error-messages="$utils.getIfExists(errors, ['reserves'])"
                suffix="%"
                type="number"
                @change="$emit('change')"
              ></InputReserve>
            </v-card-text>
          </v-card>

          <template v-if="item.type == 'HYDRO'">
            <v-expansion-panels multiple>
              <v-expansion-panel class="elevation-1">
                <v-expansion-panel-header>
                  <div class="text-h6">
                    {{ hydroSchema.monthly_energy.title }}
                  </div>
                  <div class="text-caption">
                    {{ hydroSchema.monthly_energy.tooltip }}
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="px-3">
                  <InputYearly
                    v-for="month in months"
                    :key="month"
                    v-model="item.monthly_energy[month]"
                    :error-messages="$utils.getIfExists(errors, ['monthly_energy', month])"
                    :num_years="num_years"
                    :label="month.toUpperCase()"
                    suffix="GWh"
                    type="number"
                    @change="$emit('change')"
                    fill
                    :fill-value="hydroSchema.monthly_energy.properties[month].items.default"
                  ></InputYearly>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </template>
        </template>
      </Aggregate>
    </v-col>
    <v-col cols="12" lg="8">
      <v-expansion-panels popout class="mb-3">
        <v-expansion-panel @input="chartVisible = $event" class="elevation-1">
          <template #header>
            <div class="text-h6">Generator Capacity by Fuel Type</div>
          </template>
          <ChartYearly
            :visible="chartVisible"
            type="doughnut"
            v-bind="generatorChart"
          ></ChartYearly>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
import { CaseModel, schemas } from "data-dictionary";
import fieldUtils from "@/mixins/fieldUtils";
import chartUtils from "@/mixins/chartUtils";

export default {
  name: "SystemGenerator",
  mixins: [fieldUtils, chartUtils],
  components: {
    Aggregate: () => import("@/components/Aggregate.vue"),
    InputYearly: () => import("@/components/InputYearly.vue"),
    InputReserve: () => import("@/components/InputReserve.vue"),
    InputProfile: () => import("@/components/InputProfile.vue"),
    ChartYearly: () => import("@/components/charts/ChartYearly.vue"),
    InputToggleParam: () => import("@/components/InputToggleParam.vue"),
    InputYearlyProfile: () => import("@/components/InputYearlyProfile.vue"),
  },
  props: {
    num_years: {
      type: Number,
    },
    input: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      inputMargin: 4,
      hydroSchema: schemas.filter((v) => v.$id === "generator_hydro.schema.json")[0].properties,
      renewableSchema: schemas.filter((v) => v.$id === "generator_renewable.schema.json")[0]
        .properties,
      thermalSchema: {
        ...schemas.filter((v) => v.$id === "generator_thermal.schema.json")[0].properties,
        ...schemas
          .filter((v) => v.$id === "generator_thermal.schema.json")[0]
          .allOf.map((v) => v.then.properties)
          .reduce((obj, item) => ({ ...obj, ...item }), {}),
      },
      chartVisible: null,
      months: ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"],
      headers: CaseModel.Generator.HEADERS,
      types: CaseModel.Generator.TYPES,
      sources: CaseModel.Generator.SOURCES,
    };
  },
  computed: {
    generatorChart() {
      const generators = this.input.generators.map(this.mapToValue("capacity"));
      return this.pieObjectByField("pie", generators, "source");
    },
    generatorType() {
      return this.input.generators.map((v) => v.type);
    },
  },
  watch: {
    generatorType(val) {
      val.forEach((item, i) => {
        if (item === "HYDRO" && this.input.generators[i].source !== "HYDRO") {
          this.input.generators[i].source = "HYDRO";
          this.$emit("change");
        }
      });
    },
  },
  methods: {
    generatorFactory: CaseModel.Generator,
    sourceChange($event, id) {
      if ($event === "GEOTHERMAL") {
        const item = this.input.generators.find((g) => g.id === id);
        item.fuel_price = Array(this.num_years).fill(0);
        item.heat_rate = 0;
      }
      this.$emit("change");
    },
    expansionCandidateChange($event, itemId) {
      if (!$event) {
        const item = this.input.generators.find((g) => g.id === itemId);
        item.maximum_build = Array(this.num_years).fill(0);
      }
      this.$emit("change");
    },
  },
};
</script>

<style>
.v-input__append-outer {
  margin: 0 0 0 0;
}
</style>
