<template>
  <v-row justify="center">
    <v-col cols="12" lg="8">
      <Aggregate
        title="Energy Storage Systems"
        v-model="input.storage"
        :error-messages="$utils.getIfExists(errors, ['input', 'storage'])"
        :ItemConstructor="storageFactory"
        :headers="headers"
        v-bind="$attrs"
        @change="$emit('change')"
      >
        <template #item.source="{ item, errors, allowEdit }">
          <v-select
            v-model="item.source"
            :items="sources"
            :error-messages="$utils.getIfExists(errors, ['source', 'messages'])"
            :disabled="!allowEdit || item.noDelete"
            label="Energy Storage System"
            @change="$emit('change')"
          ></v-select>
        </template>
        <template #item.capacity="{ item }">
          <td>{{ item.power_capacity[0] }} MW</td>
        </template>
        <template #default="{ item, errors }">
          <v-card class="mb-3">
            <v-card-title>
              <h6 class="text-h6">General</h6>
            </v-card-title>
            <v-card-text>
              <v-switch
                v-model="item.active"
                :label="schema.active.title"
                :hint="schema.active.tooltip"
                :error-messages="$utils.getIfExists(errors, ['active', 'messages'])"
                @change="$emit('change')"
              ></v-switch>
              <v-switch
                v-model="item.expansion_candidate"
                :error-messages="$utils.getIfExists(errors, ['expansion_candidate', 'messages'])"
                label="Expansion Candidate"
                @change="$emit('change')"
              ></v-switch>
              <v-text-field
                v-model.number="item.technical_life"
                :label="schema.technical_life.title"
                :hint="schema.technical_life.tooltip"
                :error-messages="$utils.getIfExists(errors, ['technical_life', 'messages'])"
                :suffix="schema.technical_life.unit"
                type="number"
                @change="$emit('change')"
              ></v-text-field>
              <InputToggleParam
                v-model="item.operating_status"
                :label="schema.operating_status.properties.active.title"
                :hint="schema.operating_status.properties.active.tooltip"
                :error-messages="$utils.getIfExists(errors, ['operating_status'])"
                @change="$emit('change')"
              >
                <template v-slot="{ disabled, param, errors }">
                  <InputProfile
                    v-model="param.id"
                    :hint="schema.operating_status.then.properties.param.properties.id.tooltip"
                    :error-messages="$utils.getIfExists(errors, ['id'])"
                    :disabled="disabled"
                    profile-type="DAILY_OPERATING_STATUS"
                    hide-divider
                    @change="$emit('change')"
                  ></InputProfile>
                </template>
                <!-- <template #below="{param, disabled, errors}"></template> -->
              </InputToggleParam>
              <v-text-field
                v-model.number="item.start_year"
                :label="schema.start_year.title"
                :hint="schema.start_year.tooltip"
                :error-messages="$utils.getIfExists(errors, ['start_year', 'messages'])"
                type="number"
                class="my-4"
                @change="$emit('change')"
              ></v-text-field>
              <v-text-field
                v-model.number="item.charging_efficiency"
                :label="schema.charging_efficiency.title"
                :hint="schema.charging_efficiency.tooltip"
                :error-messages="$utils.getIfExists(errors, ['charging_efficiency', 'messages'])"
                :suffix="schema.charging_efficiency.unit"
                type="number"
                class="my-4"
                @change="$emit('change')"
              ></v-text-field>
              <v-text-field
                v-model.number="item.discharging_efficiency"
                :label="schema.discharging_efficiency.title"
                :hint="schema.discharging_efficiency.tooltip"
                :error-messages="$utils.getIfExists(errors, ['discharging_efficiency', 'messages'])"
                :suffix="schema.discharging_efficiency.unit"
                type="number"
                class="my-4"
                @change="$emit('change')"
              ></v-text-field>
              <v-text-field
                v-model.number="item.ramp_rate"
                :label="schema.ramp_rate.title"
                :hint="schema.ramp_rate.tooltip"
                :error-messages="$utils.getIfExists(errors, ['ramp_rate', 'messages'])"
                :suffix="schema.ramp_rate.unit"
                type="number"
                class="my-4"
                @change="$emit('change')"
              ></v-text-field>
              <v-text-field
                v-model.number="item.soc_min"
                :label="schema.soc_min.title"
                :hint="schema.soc_min.tooltip"
                :error-messages="$utils.getIfExists(errors, ['soc_min', 'messages'])"
                :suffix="schema.soc_min.unit"
                type="number"
                class="my-4"
                @change="$emit('change')"
              ></v-text-field>
              <v-text-field
                v-model.number="item.soc_max"
                :label="schema.soc_max.title"
                :hint="schema.soc_max.tooltip"
                :error-messages="$utils.getIfExists(errors, ['soc_max', 'messages'])"
                :suffix="schema.soc_max.unit"
                type="number"
                class="my-4"
                @change="$emit('change')"
              ></v-text-field>
              <v-row>
                <v-col class="shrink">
                  <v-switch
                    v-model="item.duration"
                    :label="schema.duration.title"
                    :hint="schema.duration.tooltip"
                    :error-messages="$utils.getIfExists(errors, ['duration', 'messages'])"
                    class="pt-3 mt-1 pr-2"
                    :true-value="-1"
                    false-value="0"
                  ></v-switch>
                </v-col>
                <v-text-field
                  v-if="item.duration != -1"
                  v-model.number="item.duration"
                  :error-messages="$utils.getIfExists(errors, ['duration', 'messages'])"
                  :label="schema.duration.title"
                  :hint="schema.duration.tooltip"
                  :suffix="schema.duration.unit"
                  type="number"
                  @change="$emit('change')"
                >
                </v-text-field>
              </v-row>
              <InputProfile
                v-model="item.capacity_fade"
                :label="schema.capacity_fade.title"
                :hint="schema.capacity_fade.tooltip"
                :error-messages="$utils.getIfExists(errors, ['capacity_fade'])"
                profile-type="CAPACITY_FADE"
                :useHeaders="['cycle_limit', 'capacity_fade']"
                nullable
                @change="$emit('change')"
              ></InputProfile>
              <InputYearly
                v-model="item.estimated_cycles"
                :error-messages="$utils.getIfExists(errors, ['estimated_cycles'])"
                label="Estimated Number of Cycles"
                suffix="cycles"
                type="number"
                :num_years="num_years"
                @change="$emit('change')"
              ></InputYearly>
            </v-card-text>
          </v-card>

          <v-card class="mb-3">
            <v-card-title>
              <h6 class="text-h6">Metrics</h6>
            </v-card-title>
            <v-card-text>
              <v-switch
                v-model="item.LCOE_Calculation"
                :label="schema.LCOE_calculation.title"
                :hint="schema.LCOE_calculation.tooltip"
                :error-messages="$utils.getIfExists(errors, ['source', 'messages'])"
                @change="$emit('change')"
              ></v-switch>
            </v-card-text>
          </v-card>

          <v-card class="mb-3">
            <v-card-title>
              <h6 class="text-h6">Financial</h6>
            </v-card-title>
            <v-card-text>
              <v-text-field
                v-model.number="item.economic_life"
                :label="schema.economic_life.title"
                :hint="schema.economic_life.tooltip"
                :error-messages="$utils.getIfExists(errors, ['economic_life', 'messages'])"
                :suffix="schema.economic_life.unit"
                type="number"
                class="my-4"
                @change="$emit('change')"
              ></v-text-field>
              <v-text-field
                v-model.number="item.discount"
                :label="schema.discount.title"
                :hint="schema.discount.tooltip"
                :error-messages="$utils.getIfExists(errors, ['discount', 'messages'])"
                :placeholder="
                  input.discount && input.discount != null ? input.discount.toString() : ''
                "
                :suffix="schema.discount.unit"
                type="number"
                class="my-4"
                @change="$emit('change')"
              ></v-text-field>
            </v-card-text>
          </v-card>

          <v-card class="mb-3">
            <v-card-title>
              <h6 class="text-h6">Costs</h6>
            </v-card-title>
            <v-card-text>
              <InputYearly
                v-model="item.investment"
                :label="schema.investment.title"
                :hint="schema.investment.tooltip"
                :error-messages="$utils.getIfExists(errors, ['investment'])"
                :suffix="schema.investment.unit"
                type="number"
                :num_years="num_years"
                fill
                :fill-value="schema.investment.items.default"
                @change="$emit('change')"
              ></InputYearly>
              <InputYearly
                v-model="item.investment_energy"
                :label="schema.investment_energy.title"
                :hint="schema.investment_energy.tooltip"
                :error-messages="$utils.getIfExists(errors, ['investment_energy'])"
                :suffix="schema.investment.unit"
                type="number"
                :num_years="num_years"
                fill
                :fill-value="schema.investment_energy.items.default"
                @change="$emit('change')"
              ></InputYearly>
              <InputYearly
                v-model="item.vom"
                :label="schema.vom.title"
                :hint="schema.vom.tooltip"
                :error-messages="$utils.getIfExists(errors, ['vom'])"
                :suffix="schema.vom.unit"
                type="number"
                :num_years="num_years"
                fill
                :fill-value="schema.vom.items.default"
                @change="$emit('change')"
              ></InputYearly>
              <v-text-field
                v-model.number="item.fom"
                :label="schema.fom.title"
                :hint="schema.fom.tooltip"
                :error-messages="$utils.getIfExists(errors, ['fom', 'messages'])"
                :suffix="schema.fom.unit"
                type="number"
                :min="schema.fom.minimum"
                @change="$emit('change')"
              ></v-text-field>
              <v-text-field
                v-model.number="item.eol_cost"
                :label="schema.eol_cost.title"
                :suffix="schema.eol_cost.unit"
                type="number"
                :min="schema.eol_cost.minimum"
              >
              </v-text-field>
            </v-card-text>
          </v-card>

          <v-card class="mb-3">
            <v-card-title>
              <h6 class="text-h6">Services</h6>
            </v-card-title>
            <v-card-text>
              <InputYearly
                v-model="item.energy_arbitrage"
                :label="schema.energy_arbitrage.title"
                :hint="schema.energy_arbitrage.tooltip"
                :error-messages="$utils.getIfExists(errors, ['energy_arbitrage'])"
                :suffix="schema.energy_arbitrage.unit"
                type="number"
                :num_years="num_years"
                fill
                :fill-value="schema.energy_arbitrage.items.default"
                :min="schema.energy_arbitrage.items.minimum"
                @change="$emit('change')"
              ></InputYearly>
              <InputYearly
                v-model="item.marginal_cost"
                :label="schema.marginal_cost.title"
                :hint="schema.marginal_cost.tooltip"
                :error-messages="$utils.getIfExists(errors, ['marginal_cost'])"
                :suffix="schema.marginal_cost.unit"
                type="number"
                :num_years="num_years"
                fill
                :fill-value="schema.marginal_cost.items.default"
                :min="schema.marginal_cost.items.minimum"
                @change="$emit('change')"
              ></InputYearly>
            </v-card-text>
          </v-card>

          <v-card class="mb-3">
            <v-card-title>
              <h6 class="text-h6">Capacity</h6>
            </v-card-title>
            <v-card-text>
              <InputYearly
                v-model="item.maximum_build"
                :label="schema.maximum_build.title"
                :hint="schema.maximum_build.tooltip"
                :error-messages="$utils.getIfExists(errors, ['maximum_build'])"
                :suffix="schema.maximum_build.unit"
                type="number"
                :num_years="num_years"
                fill
                :fill-value="schema.maximum_build.items.default"
                :min="schema.maximum_build.items.minimum"
                @change="$emit('change')"
              ></InputYearly>
              <InputYearly
                v-model="item.power_capacity"
                :label="schema.power_capacity.title"
                :hint="schema.power_capacity.tooltip"
                :error-messages="$utils.getIfExists(errors, ['power_capacity'])"
                :suffix="schema.power_capacity.unit"
                type="number"
                :num_years="num_years"
                fill
                :fill-value="schema.power_capacity.items.default"
                :min="schema.power_capacity.items.minimum"
                @change="$emit('change')"
              ></InputYearly>
              <InputYearly
                v-model="item.energy_capacity"
                :label="schema.energy_capacity.title"
                :hint="schema.energy_capacity.tooltip"
                :error-messages="$utils.getIfExists(errors, ['energy_capacity'])"
                :suffix="schema.energy_capacity.unit"
                type="number"
                :num_years="num_years"
                fill
                :fill-value="schema.energy_capacity.items.default"
                :min="schema.energy_capacity.items.minimum"
                @change="$emit('change')"
              ></InputYearly>
              <v-text-field
                v-model.number="item.firm"
                :label="schema.firm.title"
                :hint="schema.firm.tooltip"
                :error-messages="$utils.getIfExists(errors, ['firm', 'messages'])"
                :suffix="schema.firm.unit"
                type="number"
                @change="$emit('change')"
              ></v-text-field>
            </v-card-text>
          </v-card>
        </template>
      </Aggregate>
    </v-col>
  </v-row>
</template>

<script>
import { CaseModel, schemas } from "data-dictionary";

export default {
  name: "SystemStorage",
  components: {
    Aggregate: () => import("@/components/Aggregate.vue"),
    InputYearly: () => import("@/components/InputYearly.vue"),
    InputToggleParam: () => import("@/components/InputToggleParam.vue"),
    InputProfile: () => import("@/components/InputProfile.vue"),
  },
  props: {
    num_years: {
      type: Number,
    },
    input: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      headers: CaseModel.Storage.HEADERS,
      sources: CaseModel.Storage.SOURCES,
      schema: schemas.filter((v) => v.$id === "storage.schema.json")[0].properties,
    };
  },
  computed: {},

  methods: {
    storageFactory: CaseModel.Storage,
  },
};
</script>

<style></style>
