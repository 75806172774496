<template>
  <v-row justify="center">
    <v-col cols="12" lg="8">
      <CardSimple title="Planning Criteria">
        <InputYearly
          v-model="input.capacity_margin"
          :error-messages="$utils.getIfExists(errors, ['input', 'capacity_margin'])"
          :label="schema.capacity_margin.title"
          :hint="schema.capacity_margin.tooltip"
          :suffix="schema.capacity_margin.unit"
          type="number"
          :num_years="num_years"
          fill
          :fill-value="schema.capacity_margin.items.default"
          @change="$emit('change')"
        ></InputYearly>
        <v-text-field
          v-model.number="input.frequency_response"
          :error-messages="$utils.getIfExists(errors, ['input', 'frequency_response', 'messages'])"
          :label="schema.frequency_response.title"
          :hint="schema.frequency_response.tooltip"
          :suffix="schema.frequency_response.unit"
          type="number"
          @change="$emit('change')"
        ></v-text-field>
        <v-text-field
          v-model.number="input.storage_frequency_percentage"
          :error-messages="
            $utils.getIfExists(errors, ['input', 'storage_frequency_percentage', 'messages'])
          "
          :label="schema.storage_frequency_percentage.title"
          :hint="schema.storage_frequency_percentage.tooltip"
          :suffix="schema.storage_frequency_percentage.unit"
          type="number"
          @change="$emit('change')"
        ></v-text-field>
        <InputYearly
          v-model="input.storage_capacity_limit"
          :error-messages="$utils.getIfExists(errors, ['input', 'storage_capacity_limit'])"
          :num_years="num_years"
          :label="schema.storage_capacity_limit.title"
          :hint="schema.storage_capacity_limit.tooltip"
          :suffix="schema.storage_capacity_limit.unit"
          type="number"
          @change="$emit('change')"
          fill
          :fill-value="schema.storage_capacity_limit.items.default"
        ></InputYearly>
      </CardSimple>
    </v-col>
    <v-col cols="12" lg="8">
      <CardSimple title="Scarcity Prices">
        <v-text-field
          v-model.number="input.dump_energy_cost"
          :error-messages="$utils.getIfExists(errors, ['input', 'dump_energy_cost', 'messages'])"
          :label="schema.storage_frequency_percentage.title"
          :hint="schema.storage_frequency_percentage.tooltip"
          :suffix="schema.storage_frequency_percentage.unit"
          @change="$emit('change')"
        ></v-text-field>
        <v-text-field
          v-model.number="input.lost_load_value"
          :error-messages="$utils.getIfExists(errors, ['input', 'lost_load_value', 'messages'])"
          :label="schema.lost_load_value.title"
          :hint="schema.lost_load_value.tooltip"
          :suffix="schema.lost_load_value.unit"
          type="number"
          @change="$emit('change')"
        ></v-text-field>
        <v-text-field
          v-for="(reserve, index) in input.reserves"
          :key="`reserve_${reserve.name}${index}`"
          v-model.number="reserve.loss_value"
          :error-messages="
            $utils.getIfExists(errors, ['input', 'reserves', index, 'loss_value', 'messages'])
          "
          :label="`Value of Lost ${reserve.name}`"
          suffix="$/MW"
          type="number"
          @change="$emit('change')"
        ></v-text-field>
        <v-text-field
          v-model.number="input.min_expost_price"
          :error-messages="$utils.getIfExists(errors, ['input', 'min_expost_price', 'messages'])"
          label="Minimum Ex-Post Price"
          suffix="$/MWh"
          type="number"
          @change="$emit('change')"
        ></v-text-field>
        <v-text-field
          v-model.number="input.max_expost_price"
          :error-messages="$utils.getIfExists(errors, ['input', 'max_expost_price', 'messages'])"
          label="Maximum Ex-Post Price"
          suffix="$/MWh"
          type="number"
          @change="$emit('change')"
        ></v-text-field>
      </CardSimple>
    </v-col>
    <v-col cols="12" lg="8">
      <SystemReserve
        title="Reserves"
        :error-messages="$utils.getIfExists(errors, ['input', 'reserves'])"
        :reserves.sync="input.reserves"
        :num_years="num_years"
        popout
        @change="$emit('change')"
      ></SystemReserve>
    </v-col>
  </v-row>
</template>

<script>
import { schemas } from "data-dictionary";

export default {
  name: "SystemReliability",
  components: {
    SystemReserve: () => import("@/components/SystemReserve"),
    CardSimple: () => import("@/components/CardSimple"),
    InputYearly: () => import("@/components/InputYearly"),
  },
  props: ["num_years", "input", "errors"],
  data() {
    return {
      schema: schemas.filter((v) => v.$id === "input.schema.json")[0].properties,
    };
  },
  computed: {},
  methods: {},
};
</script>

<style></style>
