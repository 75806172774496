<template>
  <v-row align="baseline">
    <v-col cols="12" sm="6" md="4" v-if="label">
      <span class="subtitle-1">{{ label }}</span>
      <div v-if="hint">
        <span class="caption">{{ hint }}</span>
      </div>
    </v-col>
    <v-col class="shrink">
      <Dialog
        v-if="chartable"
        icon="show_chart"
        tooltip="Show Trend"
        :title="chartTitle"
        :body="{ component: chartComponent, props: chartProps }"
      ></Dialog>
    </v-col>
    <v-col class="shrink">
      <Dialog
        v-if="tableInput"
        icon="insert_drive_file"
        tooltip="Table Edit"
        :width="500"
        :title="chartTitle"
        :body="{
          component: inputTableComponent,
          props: { value: value, num_years: num_years },
        }"
      ></Dialog>
    </v-col>
    <v-col class="grow">
      <v-row align="baseline" style="overflow-x: auto">
        <v-col
          v-for="(year, index) in num_years"
          :key="year"
          class="py-0 mx-1"
          style="min-width: 150px"
        >
          <slot
            :year="year"
            :value="value"
            :suffix="suffix"
            :type="type"
            :readonly="readonly"
            :error-messages="
              errorMessages && errorMessages[index] ? errorMessages[index].messages : null
            "
          >
            <v-text-field
              v-model.number="value[year - 1]"
              :error-messages="
                errorMessages != null && errorMessages[index] != null
                  ? errorMessages[index].messages
                  : null
              "
              :label="`Year ${year}`"
              :suffix="suffix"
              :type="type"
              :readonly="readonly"
              :min="min"
              @change="emitChange"
            ></v-text-field>
          </slot>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import Chart from "@/components/charts/ChartJs";
import InputTable from "@/components/InputTable";

export default {
  name: "InputYearly",
  components: {
    Dialog: () => import("@/components/Dialog.vue"),
  },
  props: {
    errorMessages: {
      type: [Object, Array],
      required: false,
      default: () => ({}),
    },
    label: {
      type: String,
      required: false,
    },
    caption: {
      type: String,
      required: false,
    },
    suffix: {
      type: String,
      required: false,
    },
    value: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    num_years: {
      type: Number,
      required: false,
      default: 2,
    },
    hint: {
      type: String,
      required: false,
      default: null,
    },
    chart: {
      type: Object,
      required: false,
      default: () => null,
    },
    tableInput: {
      type: Boolean,
      required: false,
      default: true,
    },
    fill: {
      type: Boolean,
      required: false,
      default: false,
    },
    fillValue: {
      type: Number,
      required: false,
      default: null,
    },
    min: {
      type: [Number, null],
      required: false,
      default: null,
    },
  },
  data() {
    return {
      viewChart: false,
      chartOptions: {
        responsive: true,
        legend: {
          labels: {
            fontSize: 20,
          },
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Year",
                fontSize: 18,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: `${this.suffix}`,
                fontSize: 18,
              },
            },
          ],
        },
        zoom: {
          enabled: false,
          mode: "xy",
        },
        pan: {
          enabled: false,
          mode: "xy",
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
      },
    };
  },
  computed: {
    inputTableComponent() {
      return InputTable;
    },
    chartable() {
      return this.type === "number" || this.chart;
    },
    chartTitle() {
      if (this.chart && this.chart.title) return this.chart.title;
      return this.label;
    },
    chartComponent() {
      if (this.chart && this.chart.component) return this.chart.component;
      return Chart;
    },
    chartProps() {
      if (this.chart && this.chart.props) return this.chart.props;
      return {
        type: "line",
        data: this.chartData,
        options: this.chartOptions,
      };
    },
    chartData() {
      const data = this.value;
      const color = this.$utils.getChartColor((Math.random() * 10).toFixed(0));
      return {
        labels: [...data.keys()].map((e) => e + 1),
        datasets: [
          {
            data,
            label: this.label,
            fill: false,
            borderColor: color,
            backgroundColor: color,
            // pointRadius: 0,
          },
        ],
      };
    },
  },
  watch: {
    num_years() {
      this.fillValues();
    },
  },
  created() {
    if (this.value == null || !Array.isArray(this.value)) {
      this.$emit("input", []);
    }
    if (!this.value.length && this.fill) {
      this.$emit("input", Array(this.num_years).fill(this.fillValue));
    }
    this.fillValues();
  },
  methods: {
    closeChart() {
      this.viewChart = false;
    },
    fillValues() {
      if (this.num_years > this.value.length && this.fill) {
        const itemsToAppend = Array(this.num_years - this.value.length).fill(this.fillValue);
        this.$emit("input", this.value.concat(itemsToAppend));
      }
    },
    emitChange() {
      this.$emit("input", this.value);
      this.$emit("change");
    },
  },
};
</script>
