<template>
  <v-card>
    <v-card-title v-if="data" class="pa-0">
      <v-btn icon :disabled="page === 1" @click="page--">
        <v-icon>keyboard_arrow_left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <span>Year {{ page }}</span>
      <v-spacer></v-spacer>
      <v-btn icon :disabled="page === numPages" @click="page++">
        <v-icon>keyboard_arrow_right</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <component
        :is="chartComponent"
        :type="type"
        :options="chartOptions"
        :data="chartData"
        v-bind="props"
      ></component>
    </v-card-text>
  </v-card>
</template>

<script>
import Chart from "@/components/charts/ChartJs";

export default {
  name: "ChartYearly",
  components: {
    Chart,
  },
  props: {
    chartComponent: {
      type: Object,
      required: false,
      default: () => Chart,
    },
    type: {
      type: String,
      required: false,
    },
    data: {
      type: [Object, Array],
      required: false,
      default: null,
    },
    options: {
      type: [Object, Array],
      required: false,
      default: null,
    },
    props: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      page: 1,
    };
  },
  computed: {
    numPages() {
      return this.data.length;
    },
    index() {
      return this.page - 1;
    },
    chartData() {
      if (Array.isArray(this.data)) {
        return this.data[this.index];
      }
      return this.data;
    },
    chartOptions() {
      if (Array.isArray(this.options)) {
        return this.options[this.index];
      }
      return this.options;
    },
  },
};
</script>

<style></style>
