<template>
  <div>
    <v-toolbar color="primary" dark tabs flat dense>
      <v-app-bar-nav-icon @click="$emit('toggle-drawer')"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ toolbarTitle }} <WikiButton :section="wikiSection" /></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-hover>
          <v-btn
            icon
            slot-scope="{ hover }"
            :color="hover ? 'accent' : ''"
            :disabled="!hasChanged"
            :loading="caseSavePending"
            @click="$emit('save')"
          >
            <v-icon>save</v-icon>
          </v-btn>
        </v-hover>
        <MenuMore offset-x offset-y>
          <v-list>
            <v-list-item color="error" @click="$emit('remove')"> Delete Case </v-list-item>
            <v-list-item @click="removeAllMessages"> Delete All Messages </v-list-item>
          </v-list>
        </MenuMore>
      </v-toolbar-items>
    </v-toolbar>

    <v-progress-linear
      v-if="caseLoadPending"
      class="mt-0"
      :indeterminate="true"
      color="secondary"
    ></v-progress-linear>

    <transition>
      <keep-alive>
        <component
          v-if="currentCase"
          :is="page.component"
          v-bind.sync="currentCase"
          :errors="errors"
          :num_years="num_years"
          @change="$emit('change')"
        ></component>
      </keep-alive>
    </transition>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import SystemSettings from "@/components/SystemSettings";
import SystemPrograms from "@/components/SystemPrograms";
import SystemReliability from "@/components/SystemReliability";
import SystemDemand from "@/components/SystemDemand";
import SystemGenerator from "@/components/SystemGenerator";
import SystemStorage from "@/components/SystemStorage";
import SystemMarket from "@/components/SystemMarket";
import SystemProject from "@/components/SystemProject";

export default {
  name: "CaseInput",
  components: {
    MenuMore: () => import("@/components/MenuMore"),
    WikiButton: () => import("@/components/WikiButton"),
  },
  props: {
    currentCase: Object,
    num_years: Number,
    hasChanged: Boolean,
    errors: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapState({}),
    ...mapGetters({
      caseSavePending: "cases/savePending",
      caseLoadPending: "cases/loadPending",
    }),
    hash() {
      return this.$route.hash.substring(1);
    },
    page() {
      return this.pages[this.hash] || this.pages.Settings;
    },
    toolbarTitle() {
      return this.onSettingsPage ? "Settings" : `Input: ${this.page.name}`;
    },
    onSettingsPage() {
      return this.page.name === "Settings";
    },
    wikiSection() {
      return this.page.wikiSection;
    },
    /* hasChanged() {
      return false;
    }, */
  },
  watch: {},
  /* beforeRouteUpdate(to, from, next) {
    const breaking
    if (this.hasChanged) {
      next(this.confirm());
    } else {
      next();
    }
  }, */
  created() {},
  methods: {
    ...mapActions({
      removeMessages: "messages/remove",
    }),
    removeAllMessages() {
      if (
        // eslint-disable-next-line no-alert
        window.confirm(
          "Warning: Deleted messages are non-recoverable. Are you sure you want to continue?"
        )
      ) {
        this.removeMessages([null, { query: { case_id: this.currentCase.id } }]);
      }
    },
    confirmLeave() {
      // eslint-disable-next-line no-alert
      return window.confirm("You have unsaved changes! Are you sure you want to leave?");
    },
  },
  data() {
    return {
      newCase: null,
      settingsDialog: null,
      pages: {
        Settings: {
          name: "Settings",
          component: SystemSettings,
          wikiSection: "Settings/General%20Settings.html",
        },
        Programs: {
          name: "Programs",
          component: SystemPrograms,
          wikiSection: "Inputs/Programs.html",
        },
        ReliabilityCriteria: {
          name: "Reliability Criteria",
          component: SystemReliability,
          wikiSection: "Inputs/Reliability%20Criteria.html",
        },
        Demand: {
          name: "Demand",
          component: SystemDemand,
          wikiSection: "Inputs/Demand.html",
        },
        Generators: {
          name: "Generators",
          component: SystemGenerator,
          wikiSection: "Inputs/Generators.html",
        },
        EnergyStorage: {
          name: "Energy Storage",
          component: SystemStorage,
          wikiSection: "Inputs/Energy%20Storage.html",
        },
        Market: {
          name: "Market",
          component: SystemMarket,
          wikiSection: "",
        },
        Project: {
          name: "Project",
          component: SystemProject,
          wikiSection: "Inputs/Project.html",
        },
      },
    };
  },
};
</script>

<style></style>
