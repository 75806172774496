var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","lg":"8"}},[_c('Aggregate',{attrs:{"title":"Projects","error-messages":_vm.$utils.getIfExists(_vm.errors, ['input', 'projects']),"ItemConstructor":_vm.CaseModel.Project,"headers":_vm.CaseModel.Project.HEADERS,"no-expand":""},on:{"change":function($event){return _vm.$emit('change')}},scopedSlots:_vm._u([{key:"item.power_capacity",fn:function(ref){
var item = ref.item;
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":_vm.$utils.getIfExists(errors, ['power_capacity', 'messages']),"suffix":"MW","type":"number","single-line":""},on:{"change":function($event){return _vm.$emit('change')}},model:{value:(item.power_capacity),callback:function ($$v) {_vm.$set(item, "power_capacity", _vm._n($$v))},expression:"item.power_capacity"}})]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":_vm.$utils.getIfExists(errors, ['duration', 'messages']),"suffix":"hours","type":"number","single-line":""},on:{"change":function($event){return _vm.$emit('change')}},model:{value:(item.duration),callback:function ($$v) {_vm.$set(item, "duration", _vm._n($$v))},expression:"item.duration"}})]}},{key:"item.storage_efficiency",fn:function(ref){
var item = ref.item;
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":_vm.$utils.getIfExists(errors, ['storage_efficiency', 'messages']),"suffix":"%","type":"number","single-line":""},on:{"change":function($event){return _vm.$emit('change')}},model:{value:(item.storage_efficiency),callback:function ($$v) {_vm.$set(item, "storage_efficiency", _vm._n($$v))},expression:"item.storage_efficiency"}})]}},{key:"item.soc_initial",fn:function(ref){
var item = ref.item;
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":_vm.$utils.getIfExists(errors, ['soc_initial', 'messages']),"suffix":"%","type":"number","single-line":""},on:{"change":function($event){return _vm.$emit('change')}},model:{value:(item.soc_initial),callback:function ($$v) {_vm.$set(item, "soc_initial", _vm._n($$v))},expression:"item.soc_initial"}})]}},{key:"item.soc_low",fn:function(ref){
var item = ref.item;
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":_vm.$utils.getIfExists(errors, ['soc_low', 'messages']),"suffix":"%","type":"number","single-line":""},on:{"change":function($event){return _vm.$emit('change')}},model:{value:(item.soc_low),callback:function ($$v) {_vm.$set(item, "soc_low", _vm._n($$v))},expression:"item.soc_low"}})]}},{key:"item.soc_high",fn:function(ref){
var item = ref.item;
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":_vm.$utils.getIfExists(errors, ['soc_high', 'messages']),"suffix":"%","type":"number","single-line":""},on:{"change":function($event){return _vm.$emit('change')}},model:{value:(item.soc_high),callback:function ($$v) {_vm.$set(item, "soc_high", _vm._n($$v))},expression:"item.soc_high"}})]}},{key:"item.capacity_fade",fn:function(ref){
var item = ref.item;
var errors = ref.errors;
return [_c('InputProfile',{attrs:{"error-messages":_vm.$utils.getIfExists(errors, ['capacity_fade']),"profile-type":"CAPACITY_FADE","single-line":"","useHeaders":['cycle_limit', 'capacity_fade'],"nullable":""},on:{"change":function($event){return _vm.$emit('change')}},model:{value:(item.capacity_fade),callback:function ($$v) {_vm.$set(item, "capacity_fade", $$v)},expression:"item.capacity_fade"}})]}}]),model:{value:(_vm.input.projects),callback:function ($$v) {_vm.$set(_vm.input, "projects", $$v)},expression:"input.projects"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"8"}},[_c('CardSimple',{attrs:{"title":"Services","textClass":""}},[_c('InputToggleParam',{attrs:{"error-messages":_vm.$utils.getIfExists(_vm.errors, ['input', 'energy_arbitrage']),"label":"Energy Arbitrage"},on:{"change":function($event){return _vm.$emit('change')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var param = ref.param;
var disabled = ref.disabled;
var errors = ref.errors;
return [(_vm.sequencingIsActive)?_c('v-col',{staticClass:"grow"},[_c('InputToggleParam',{attrs:{"error-messages":_vm.$utils.getIfExists(errors, ['sequencing']),"label":"Sequencing","hide-divider":""},on:{"change":function($event){return _vm.$emit('change')}},model:{value:(param.sequencing),callback:function ($$v) {_vm.$set(param, "sequencing", $$v)},expression:"param.sequencing"}})],1):_vm._e(),_c('v-col',{staticClass:"grow"},[_c('v-text-field',{attrs:{"error-messages":_vm.$utils.getIfExists(errors, ['capacity_allocation', 'messages']),"disabled":disabled,"label":"Power Nomination","suffix":"MW","type":"number","hint":"Set to -1 for automatic allocation"},on:{"change":function($event){return _vm.$emit('change')}},model:{value:(param.capacity_allocation),callback:function ($$v) {_vm.$set(param, "capacity_allocation", _vm._n($$v))},expression:"param.capacity_allocation"}})],1)]}}]),model:{value:(_vm.input.energy_arbitrage),callback:function ($$v) {_vm.$set(_vm.input, "energy_arbitrage", $$v)},expression:"input.energy_arbitrage"}}),_vm._l((_vm.input.reserves),function(r,index){return _c('InputToggleParam',{key:("reserve_" + (r.id)),attrs:{"label":r.name,"error-messages":_vm.$utils.getIfExists(_vm.errors, ['input', 'reserves', index, 'service'])},on:{"change":function($event){return _vm.$emit('change')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var disabled = ref.disabled;
var param = ref.param;
var errors = ref.errors;
return [(_vm.sequencingIsActive)?_c('v-col',{staticClass:"grow"},[_c('InputToggleParam',{attrs:{"error-messages":_vm.$utils.getIfExists(errors, ['sequencing']),"label":"Sequencing","hide-divider":""},on:{"change":function($event){return _vm.$emit('change')}},model:{value:(param.sequencing),callback:function ($$v) {_vm.$set(param, "sequencing", $$v)},expression:"param.sequencing"}})],1):_vm._e(),_c('v-col',{staticClass:"grow"},[_c('v-text-field',{attrs:{"error-messages":_vm.$utils.getIfExists(errors, ['capacity_allocation', 'messages']),"disabled":disabled,"label":"Capacity Allocation","suffix":"MW","type":"number","hint":"Set to -1 for automatic allocation"},on:{"change":function($event){return _vm.$emit('change')}},model:{value:(param.capacity_allocation),callback:function ($$v) {_vm.$set(param, "capacity_allocation", _vm._n($$v))},expression:"param.capacity_allocation"}})],1)]}}],null,true),model:{value:(r.service),callback:function ($$v) {_vm.$set(r, "service", $$v)},expression:"r.service"}})}),_c('InputToggleParam',{attrs:{"error-messages":_vm.$utils.getIfExists(_vm.errors, ['input', 'peak_shaving']),"label":"Peak Shaving"},on:{"change":function($event){return _vm.$emit('change')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var param = ref.param;
var disabled = ref.disabled;
var errors = ref.errors;
return [_c('v-col',{staticClass:"grow"},[_c('v-text-field',{attrs:{"error-messages":_vm.$utils.getIfExists(errors, ['capacity_allocation', 'messages']),"disabled":disabled,"label":"Capacity Allocation","suffix":"MW","type":"number","hint":"Set to -1 for automatic allocation"},on:{"change":function($event){return _vm.$emit('change')}},model:{value:(param.capacity_allocation),callback:function ($$v) {_vm.$set(param, "capacity_allocation", _vm._n($$v))},expression:"param.capacity_allocation"}})],1)]}},{key:"below",fn:function(ref){
var param = ref.param;
var disabled = ref.disabled;
var errors = ref.errors;
return [_c('v-col',{staticClass:"grow"},[_c('v-range-slider',{attrs:{"error-messages":_vm.$utils.getIfExists(errors, ['peak_hours', 'messages']),"label":"Peak Hours","min":1,"max":24,"thumb-label":"","disabled":disabled},on:{"change":function($event){return _vm.$emit('change')}},model:{value:(param.peak_hours),callback:function ($$v) {_vm.$set(param, "peak_hours", $$v)},expression:"param.peak_hours"}})],1)]}}]),model:{value:(_vm.input.peak_shaving),callback:function ($$v) {_vm.$set(_vm.input, "peak_shaving", $$v)},expression:"input.peak_shaving"}}),_c('InputToggleParam',{attrs:{"error-messages":_vm.$utils.getIfExists(_vm.errors, ['input', 'renewable_shifting']),"label":"Renewable Shifting"},on:{"change":function($event){return _vm.$emit('change')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var param = ref.param;
var disabled = ref.disabled;
var errors = ref.errors;
return [_c('v-row',[_c('v-text-field',{staticClass:"px-1",attrs:{"error-messages":_vm.$utils.getIfExists(errors, ['capacity_allocation', 'messages']),"disabled":disabled,"label":"Capacity Allocation","suffix":"MW","type":"number","hint":"Set to -1 for automatic allocation"},on:{"change":function($event){return _vm.$emit('change')}},model:{value:(param.capacity_allocation),callback:function ($$v) {_vm.$set(param, "capacity_allocation", _vm._n($$v))},expression:"param.capacity_allocation"}}),_c('v-text-field',{staticClass:"px-1",attrs:{"error-messages":_vm.$utils.getIfExists(errors, ['capacity', 'messages']),"disabled":disabled,"label":"Renewable Capacity","suffix":"MW","type":"number"},on:{"change":function($event){return _vm.$emit('change')}},model:{value:(param.capacity),callback:function ($$v) {_vm.$set(param, "capacity", _vm._n($$v))},expression:"param.capacity"}})],1)]}},{key:"below",fn:function(ref){
var param = ref.param;
var disabled = ref.disabled;
var errors = ref.errors;
return [_c('v-row',[_c('v-col',{staticClass:"px-1",attrs:{"cols":"4"}},[_c('v-radio-group',{model:{value:(param.generator.id),callback:function ($$v) {_vm.$set(param.generator, "id", $$v)},expression:"param.generator.id"}},_vm._l((_vm.renewable),function(gen){return _c('v-radio',{key:("generator_" + (gen.name)),attrs:{"label":((gen.name) + " - " + (gen.source)),"value":gen.id,"disabled":disabled},on:{"change":function($event){return _vm.$emit('change')}}})}),1)],1),_c('v-slide-x-transition',[(param.generator.id)?_c('v-col',{staticClass:"px-1"},[_c('InputProfile',{attrs:{"error-messages":_vm.$utils.getIfExists(errors, ['generator', 'profile']),"profile-type":_vm.renewable.find(function (e) { return e.id === param.generator.id; }).source,"disabled":disabled,"label":"Renewable Profile","hint":"Enter a name to upload a new profile","persistent-hint":"","show-chart":""},on:{"change":function($event){return _vm.$emit('change')}},model:{value:(param.generator.profile),callback:function ($$v) {_vm.$set(param.generator, "profile", $$v)},expression:"param.generator.profile"}}),(_vm.sequencingIsActive)?_c('InputProfile',{attrs:{"error-messages":_vm.$utils.getIfExists(errors, ['generator', 'rt_profile']),"profile-type":_vm.renewable.find(function (e) { return e.id === param.generator.id; }).source,"disabled":disabled,"label":"RT Renewable Profile","hint":"Enter a name to upload a new profile","persistent-hint":"","show-chart":""},on:{"change":function($event){return _vm.$emit('change')}},model:{value:(param.generator.rt_profile),callback:function ($$v) {_vm.$set(param.generator, "rt_profile", $$v)},expression:"param.generator.rt_profile"}}):_vm._e()],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12"}},[(!disabled)?_c('InputToggleParam',{attrs:{"error-messages":_vm.$utils.getIfExists(errors, ['ppa']),"label":"PPA","disabled":disabled,"hide-divider":""},on:{"change":function($event){return _vm.$emit('change')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var disabled = ref.disabled;
var param = ref.param;
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":_vm.$utils.getIfExists(errors, ['ppa']),"disabled":disabled,"label":"Price","suffix":"$/MWh","type":"number"},on:{"change":function($event){return _vm.$emit('change')}},model:{value:(param.ppa),callback:function ($$v) {_vm.$set(param, "ppa", _vm._n($$v))},expression:"param.ppa"}})]}}],null,true),model:{value:(param.ppa),callback:function ($$v) {_vm.$set(param, "ppa", $$v)},expression:"param.ppa"}}):_vm._e()],1)]}}]),model:{value:(_vm.input.renewable_shifting),callback:function ($$v) {_vm.$set(_vm.input, "renewable_shifting", $$v)},expression:"input.renewable_shifting"}}),_c('InputToggleParam',{attrs:{"error-messages":_vm.$utils.getIfExists(_vm.errors, ['input', 'demand_constraints']),"label":"Demand","hide-divider":""},on:{"change":function($event){return _vm.$emit('change')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var param = ref.param;
var disabled = ref.disabled;
var errors = ref.errors;
return [_c('v-col',{staticClass:"grow"},[_c('InputYearly',{attrs:{"error-messages":_vm.$utils.getIfExists(errors, ['profiles']),"num_years":_vm.num_years,"disabled":disabled},on:{"change":function($event){return _vm.$emit('change')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var year = ref.year;
var errorMessages = ref.errorMessages;
return [_c('InputProfile',{attrs:{"error-messages":errorMessages,"disabled":disabled,"profile-type":"DEMAND","label":("Year " + year),"hint":"Enter a name to upload a new profile","show-chart":""},on:{"change":function($event){return _vm.$emit('change')}},model:{value:(value[year - 1]),callback:function ($$v) {_vm.$set(value, year - 1, $$v)},expression:"value[year - 1]"}})]}}],null,true),model:{value:(param.profiles),callback:function ($$v) {_vm.$set(param, "profiles", $$v)},expression:"param.profiles"}})],1)]}}]),model:{value:(_vm.input.demand_constraints),callback:function ($$v) {_vm.$set(_vm.input, "demand_constraints", $$v)},expression:"input.demand_constraints"}})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }