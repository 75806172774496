<template>
  <v-row justify="center">
    <v-col cols="12" lg="8">
      <Aggregate
        title="Markets"
        v-model="input.markets"
        :error-messages="$utils.getIfExists(errors, ['input', 'market'])"
        :ItemConstructor="marketFactory"
        v-bind="$attrs"
        @change="$emit('change')"
      >
        <template #default="{ item, errors }">
          <v-card class="mb-3">
            <v-card-text>
              <v-switch
                v-model="item.constant_price"
                :label="schema.constant_price.title"
                :hint="schema.constant_price.tooltip"
                persistent-hint
                :error-messages="$utils.getIfExists(errors, ['constant_price', 'messages'])"
                @change="$emit('change')"
              ></v-switch>
              <InputYearly
                v-model="item.price"
                v-if="item.constant_price"
                :label="schema.price.title"
                :hint="schema.price.tooltip"
                :error-messages="$utils.getIfExists(errors, ['price', 'messages'])"
                :suffix="schema.price.unit"
                type="number"
                :num_years="num_years"
                fill
                :fill-value="schema.price.items.default"
                :min="schema.price.items.minimum"
                @change="$emit('change')"
              ></InputYearly>
              <InputYearlyProfile
                v-model="item.price_profiles"
                v-else
                :label="schema.price_profiles.title"
                :hint="schema.price_profiles.tooltip"
                :error-messages="$utils.getIfExists(errors, ['price_profiles'])"
                :num_years="num_years"
                profile-type="ENERGY_PRICE"
                @change="$emit('change')"
              ></InputYearlyProfile>
              <v-switch
                v-model="item.constant_buy"
                :label="schema.constant_buy.title"
                :hint="schema.constant_buy.tooltip"
                persistent-hint
                :error-messages="$utils.getIfExists(errors, ['constant_buy', 'messages'])"
                @change="$emit('change')"
              ></v-switch>
              <InputYearly
                v-model="item.buy_limit"
                v-if="item.constant_buy"
                label="Hourly Purchase Limit "
                :hint="schema.buy_limit.tooltip"
                :error-messages="$utils.getIfExists(errors, ['buy_limit', 'messages'])"
                :suffix="schema.buy_limit.unit"
                type="number"
                :num_years="num_years"
                fill
                :fill-value="schema.buy_limit.items.default"
                :min="schema.buy_limit.items.minimum"
                @change="$emit('change')"
              ></InputYearly>
              <InputYearlyProfile
                v-model="item.buy_limit_profiles"
                v-else
                label="Hourly Purchase Limit Profile "
                :hint="schema.buy_limit_profiles.tooltip"
                :error-messages="$utils.getIfExists(errors, ['buy_limit_profiles'])"
                :num_years="num_years"
                profile-type="BUY_LIMIT"
                @change="$emit('change')"
              ></InputYearlyProfile>
              <v-switch
                v-model="item.constant_sell"
                label="Constant Sale Limit"
                :hint="schema.constant_sell.tooltip"
                persistent-hint
                :error-messages="$utils.getIfExists(errors, ['constant_sell', 'messages'])"
                @change="$emit('change')"
              ></v-switch>
              <InputYearly
                v-model="item.sell_limit"
                v-if="item.constant_sell"
                label="Hourly Sale Limit"
                :hint="schema.sell_limit.tooltip"
                :error-messages="$utils.getIfExists(errors, ['sell_limit', 'messages'])"
                :suffix="schema.sell_limit.unit"
                type="number"
                :num_years="num_years"
                fill
                :fill-value="schema.sell_limit.items.default"
                :min="schema.sell_limit.items.minimum"
                @change="$emit('change')"
              ></InputYearly>
              <InputYearlyProfile
                v-model="item.sell_limit_profiles"
                v-else
                label="Hourly Sale Limit Profile"
                :hint="schema.sell_limit_profiles.tooltip"
                :error-messages="$utils.getIfExists(errors, ['sell_limit_profiles'])"
                :num_years="num_years"
                profile-type="SELL_LIMIT"
                @change="$emit('change')"
              ></InputYearlyProfile>
              <InputYearly
                v-model="item.net_sell_limits"
                label="Net Sales Limits"
                :hint="schema.net_sell_limits.tooltip"
                :error-messages="$utils.getIfExists(errors, ['net_sell_limits', 'messages'])"
                :suffix="schema.net_sell_limits.unit"
                type="number"
                :num_years="num_years"
                fill
                :fill-value="schema.net_sell_limits.items.default"
                :min="schema.net_sell_limits.items.minimum"
                @change="$emit('change')"
              ></InputYearly>
              <InputYearly
                v-model="item.net_buy_limits"
                label="Net Purchases Limits"
                :hint="schema.net_buy_limits.tooltip"
                :error-messages="$utils.getIfExists(errors, ['net_buy_limits', 'messages'])"
                :suffix="schema.net_buy_limits.unit"
                type="number"
                :num_years="num_years"
                fill
                :fill-value="schema.net_buy_limits.items.default"
                :min="schema.net_buy_limits.items.minimum"
                @change="$emit('change')"
              ></InputYearly>
              <v-text-field
                v-model.number="item.firm_capacity"
                :label="schema.firm_capacity.title"
                :hint="schema.firm_capacity.tooltip"
                :error-messages="$utils.getIfExists(errors, ['firm_capacity', 'messages'])"
                :suffix="schema.firm_capacity.unit"
                type="number"
                @change="$emit('change')"
              ></v-text-field>
            </v-card-text>
          </v-card>
        </template>
      </Aggregate>
    </v-col>
  </v-row>
</template>

<script>
import { CaseModel, schemas } from "data-dictionary";

export default {
  name: "SystemMarket",
  components: {
    Aggregate: () => import("@/components/Aggregate.vue"),
    InputYearly: () => import("@/components/InputYearly.vue"),
    InputYearlyProfile: () => import("@/components/InputYearlyProfile.vue"),
  },
  props: {
    num_years: {
      type: Number,
    },
    input: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      schemas,
      schema: {
        ...schemas.filter((v) => v.$id === "market.schema.json")[0].properties,
        ...schemas
          .filter((v) => v.$id === "market.schema.json")[0]
          .allOf.map((v) => v.then.properties)
          .reduce((obj, item) => ({ ...obj, ...item }), {}),
      },
    };
  },
  computed: {},

  methods: {
    marketFactory: CaseModel.Market,
  },
};
</script>

<style></style>
