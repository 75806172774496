<template>
  <v-sheet class="text-center pa-2">
    <span v-if="!chartData">No Data available</span>
    <div v-else>
      <canvas ref="chart" @after-render="afterRender"></canvas>
    </div>
  </v-sheet>
</template>

<script>
import Chart from "chart.js";
/* import ChartLabels from 'chartjs-plugin-datalabels';
import ChartZoom from 'chartjs-plugin-zoom'; */
import { kebabCase } from "@/plugins/utils";

const eventHooks = {
  id: "eventHooks",
};

[
  "beforeInit",
  "afterInit",
  "beforeUpdate",
  "afterUpdate",
  "beforeLayout",
  "afterLayout",
  "beforeDatasetsUpdate",
  "afterDatasetsUpdate",
  "beforeDatasetUpdate",
  "afterDatasetUpdate",
  "beforeRender",
  "afterRender",
  "beforeDraw",
  "afterDraw",
  "beforeDatasetsDraw",
  "afterDatasetsDraw",
  "beforeDatasetDraw",
  "afterDatasetDraw",
  "beforeEvent",
  "afterEvent",
].forEach((eventName) => {
  eventHooks[eventName] = (chart, options) => {
    chart.canvas.dispatchEvent(new Event(kebabCase(eventName)));
    if (options[eventName] instanceof Function) {
      options[eventName](chart, options);
    }
  };
});

Chart.plugins.register(eventHooks);

export default {
  name: "Chart",
  props: {
    type: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: false,
    },
    options: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  computed: {
    chartData() {
      return this.data;
    },
    chartOptions() {
      return this.options;
    },
  },
  watch: {
    /* type(n) {
      this.chart.type = n;
      this.chart.update();
    }, */
    currentPage() {
      this.updateChart();
    },
    data(to, from) {
      // Only check this kind of equality if doughnut or pie charts
      /* if (['doughnut', 'pie'].find(e => e === this.type)) {
        let array1 = to.datasets[0].data;
        let array2 = to.datasets[0].data;
        // if to & from data are not equal
        if (
          !(
            array1.length === array2.length &&
            array1.every((value, index) => value === array2[index])
          )
        ) {
          this.updateChart();
        }
      } else {
        } */
      if (from == null) {
        this.createChart();
      } else {
        this.updateChart();
      }
    },
    options() {
      this.updateChart();
    },
  },
  mounted() {
    if (this.chartData) {
      this.createChart();
    }
  },
  beforeDestroy() {
    this.destroyChart();
  },
  methods: {
    updateChart() {
      if (this.chart) {
        setTimeout(() => {
          console.debug("Updating Chart");
          this.chart.data = this.chartData;
          this.chart.options = this.chartOptions;
          this.chart.update();
        }, 100);
      }
    },
    createChart() {
      this.isLoading = true;
      setTimeout(() => {
        console.debug("Creating Chart");
        const ctx = this.$refs.chart;
        this.chart = new Chart(ctx, {
          type: this.type,
          data: this.chartData,
          options: this.chartOptions,
        });
      }, 100);
    },
    destroyChart() {
      if (this.chart) {
        console.debug("Destroying Chart");
        this.chart.destroy();
        this.chart = null;
        this.isLoading = null;
      }
    },
    afterRender() {
      this.isLoading = false;
    },
  },
};
</script>

<style></style>
