<template>
  <v-row justify="center">
    <v-col cols="12" lg="8">
      <Aggregate
        title="Projects"
        v-model="input.projects"
        :error-messages="$utils.getIfExists(errors, ['input', 'projects'])"
        :ItemConstructor="CaseModel.Project"
        :headers="CaseModel.Project.HEADERS"
        no-expand
        @change="$emit('change')"
      >
        <template #item.power_capacity="{ item, errors }">
          <v-text-field
            v-model.number="item.power_capacity"
            :error-messages="$utils.getIfExists(errors, ['power_capacity', 'messages'])"
            suffix="MW"
            type="number"
            single-line
            @change="$emit('change')"
          ></v-text-field>
        </template>
        <template #item.duration="{ item, errors }">
          <v-text-field
            v-model.number="item.duration"
            :error-messages="$utils.getIfExists(errors, ['duration', 'messages'])"
            suffix="hours"
            type="number"
            single-line
            @change="$emit('change')"
          ></v-text-field>
        </template>
        <template #item.storage_efficiency="{ item, errors }">
          <v-text-field
            v-model.number="item.storage_efficiency"
            :error-messages="$utils.getIfExists(errors, ['storage_efficiency', 'messages'])"
            suffix="%"
            type="number"
            single-line
            @change="$emit('change')"
          ></v-text-field>
        </template>
        <template #item.soc_initial="{ item, errors }">
          <v-text-field
            v-model.number="item.soc_initial"
            :error-messages="$utils.getIfExists(errors, ['soc_initial', 'messages'])"
            suffix="%"
            type="number"
            single-line
            @change="$emit('change')"
          ></v-text-field>
        </template>
        <template #item.soc_low="{ item, errors }">
          <v-text-field
            v-model.number="item.soc_low"
            :error-messages="$utils.getIfExists(errors, ['soc_low', 'messages'])"
            suffix="%"
            type="number"
            single-line
            @change="$emit('change')"
          ></v-text-field>
        </template>
        <template #item.soc_high="{ item, errors }">
          <v-text-field
            v-model.number="item.soc_high"
            :error-messages="$utils.getIfExists(errors, ['soc_high', 'messages'])"
            suffix="%"
            type="number"
            single-line
            @change="$emit('change')"
          ></v-text-field>
        </template>
        <template #item.capacity_fade="{ item, errors }">
          <InputProfile
            v-model="item.capacity_fade"
            :error-messages="$utils.getIfExists(errors, ['capacity_fade'])"
            profile-type="CAPACITY_FADE"
            single-line
            :useHeaders="['cycle_limit', 'capacity_fade']"
            nullable
            @change="$emit('change')"
          ></InputProfile>
        </template>
        <!-- <template #default="{item, errors}"></template> -->
      </Aggregate>
    </v-col>
    <v-col cols="12" lg="8">
      <CardSimple title="Services" textClass>
        <InputToggleParam
          v-model="input.energy_arbitrage"
          :error-messages="$utils.getIfExists(errors, ['input', 'energy_arbitrage'])"
          label="Energy Arbitrage"
          @change="$emit('change')"
        >
          <template v-slot="{ param, disabled, errors }">
            <v-col class="grow" v-if="sequencingIsActive">
              <InputToggleParam
                v-model="param.sequencing"
                :error-messages="$utils.getIfExists(errors, ['sequencing'])"
                label="Sequencing"
                hide-divider
                @change="$emit('change')"
              ></InputToggleParam>
            </v-col>
            <v-col class="grow">
              <v-text-field
                v-model.number="param.capacity_allocation"
                :error-messages="$utils.getIfExists(errors, ['capacity_allocation', 'messages'])"
                :disabled="disabled"
                label="Power Nomination"
                suffix="MW"
                type="number"
                hint="Set to -1 for automatic allocation"
                @change="$emit('change')"
              ></v-text-field>
            </v-col>
          </template>
          <!-- <template #below="{param, disabled}"></template> -->
        </InputToggleParam>
        <InputToggleParam
          v-for="(r, index) in input.reserves"
          :key="`reserve_${r.id}`"
          :label="r.name"
          v-model="r.service"
          :error-messages="$utils.getIfExists(errors, ['input', 'reserves', index, 'service'])"
          @change="$emit('change')"
        >
          <template v-slot="{ disabled, param, errors }">
            <v-col class="grow" v-if="sequencingIsActive">
              <InputToggleParam
                v-model="param.sequencing"
                :error-messages="$utils.getIfExists(errors, ['sequencing'])"
                label="Sequencing"
                hide-divider
                @change="$emit('change')"
              ></InputToggleParam>
            </v-col>
            <v-col class="grow">
              <v-text-field
                v-model.number="param.capacity_allocation"
                :error-messages="$utils.getIfExists(errors, ['capacity_allocation', 'messages'])"
                :disabled="disabled"
                label="Capacity Allocation"
                suffix="MW"
                type="number"
                hint="Set to -1 for automatic allocation"
                @change="$emit('change')"
              ></v-text-field>
            </v-col>
          </template>
          <!-- <template #below="{param, disabled}"></template> -->
        </InputToggleParam>
        <InputToggleParam
          v-model="input.peak_shaving"
          :error-messages="$utils.getIfExists(errors, ['input', 'peak_shaving'])"
          label="Peak Shaving"
          @change="$emit('change')"
        >
          <template v-slot="{ param, disabled, errors }">
            <v-col class="grow">
              <v-text-field
                v-model.number="param.capacity_allocation"
                :error-messages="$utils.getIfExists(errors, ['capacity_allocation', 'messages'])"
                :disabled="disabled"
                label="Capacity Allocation"
                suffix="MW"
                type="number"
                hint="Set to -1 for automatic allocation"
                @change="$emit('change')"
              ></v-text-field>
            </v-col>
          </template>
          <template #below="{ param, disabled, errors }">
            <v-col class="grow">
              <v-range-slider
                v-model="param.peak_hours"
                :error-messages="$utils.getIfExists(errors, ['peak_hours', 'messages'])"
                label="Peak Hours"
                :min="1"
                :max="24"
                thumb-label
                :disabled="disabled"
                @change="$emit('change')"
              ></v-range-slider>
            </v-col>
          </template>
        </InputToggleParam>
        <InputToggleParam
          v-model="input.renewable_shifting"
          :error-messages="$utils.getIfExists(errors, ['input', 'renewable_shifting'])"
          label="Renewable Shifting"
          @change="$emit('change')"
        >
          <template v-slot="{ param, disabled, errors }">
            <v-row>
              <v-text-field
                v-model.number="param.capacity_allocation"
                :error-messages="$utils.getIfExists(errors, ['capacity_allocation', 'messages'])"
                :disabled="disabled"
                label="Capacity Allocation"
                suffix="MW"
                type="number"
                class="px-1"
                hint="Set to -1 for automatic allocation"
                @change="$emit('change')"
              ></v-text-field>
              <v-text-field
                v-model.number="param.capacity"
                :error-messages="$utils.getIfExists(errors, ['capacity', 'messages'])"
                :disabled="disabled"
                label="Renewable Capacity"
                suffix="MW"
                type="number"
                class="px-1"
                @change="$emit('change')"
              ></v-text-field>
            </v-row>
          </template>
          <template #below="{ param, disabled, errors }">
            <v-row>
              <v-col class="px-1" cols="4">
                <v-radio-group v-model="param.generator.id">
                  <v-radio
                    v-for="gen in renewable"
                    :key="`generator_${gen.name}`"
                    :label="`${gen.name} - ${gen.source}`"
                    :value="gen.id"
                    :disabled="disabled"
                    @change="$emit('change')"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-slide-x-transition>
                <v-col class="px-1" v-if="param.generator.id">
                  <InputProfile
                    v-model="param.generator.profile"
                    :error-messages="$utils.getIfExists(errors, ['generator', 'profile'])"
                    :profile-type="renewable.find((e) => e.id === param.generator.id).source"
                    :disabled="disabled"
                    label="Renewable Profile"
                    hint="Enter a name to upload a new profile"
                    persistent-hint
                    show-chart
                    @change="$emit('change')"
                  ></InputProfile>
                  <InputProfile
                    v-if="sequencingIsActive"
                    v-model="param.generator.rt_profile"
                    :error-messages="$utils.getIfExists(errors, ['generator', 'rt_profile'])"
                    :profile-type="renewable.find((e) => e.id === param.generator.id).source"
                    :disabled="disabled"
                    label="RT Renewable Profile"
                    hint="Enter a name to upload a new profile"
                    persistent-hint
                    show-chart
                    @change="$emit('change')"
                  ></InputProfile>
                </v-col>
              </v-slide-x-transition>
            </v-row>

            <v-col cols="12">
              <InputToggleParam
                v-if="!disabled"
                v-model="param.ppa"
                :error-messages="$utils.getIfExists(errors, ['ppa'])"
                label="PPA"
                :disabled="disabled"
                hide-divider
                @change="$emit('change')"
              >
                <template v-slot="{ disabled, param, errors }">
                  <v-text-field
                    v-model.number="param.ppa"
                    :error-messages="$utils.getIfExists(errors, ['ppa'])"
                    :disabled="disabled"
                    label="Price"
                    suffix="$/MWh"
                    type="number"
                    @change="$emit('change')"
                  ></v-text-field>
                </template>
              </InputToggleParam>
            </v-col>
          </template>
        </InputToggleParam>
        <InputToggleParam
          v-model="input.demand_constraints"
          :error-messages="$utils.getIfExists(errors, ['input', 'demand_constraints'])"
          label="Demand"
          hide-divider
          @change="$emit('change')"
        >
          <template v-slot="{ param, disabled, errors }">
            <v-col class="grow">
              <InputYearly
                v-model="param.profiles"
                :error-messages="$utils.getIfExists(errors, ['profiles'])"
                :num_years="num_years"
                :disabled="disabled"
                @change="$emit('change')"
              >
                <template #default="{ value, year, errorMessages }">
                  <InputProfile
                    v-model="value[year - 1]"
                    :error-messages="errorMessages"
                    :disabled="disabled"
                    profile-type="DEMAND"
                    :label="`Year ${year}`"
                    hint="Enter a name to upload a new profile"
                    show-chart
                    @change="$emit('change')"
                  ></InputProfile>
                </template>
              </InputYearly>
            </v-col>
          </template>
        </InputToggleParam>
      </CardSimple>
    </v-col>
  </v-row>
</template>

<script>
import { CaseModel } from "data-dictionary";

export default {
  name: "SystemProject",
  props: ["input", "num_years", "settings", "errors"],
  components: {
    Aggregate: () => import("@/components/Aggregate.vue"),
    CardSimple: () => import("@/components/CardSimple.vue"),
    InputToggleParam: () => import("@/components/InputToggleParam.vue"),
    InputProfile: () => import("@/components/InputProfile.vue"),
    InputYearly: () => import("@/components/InputYearly.vue"),
  },
  computed: {
    renewable() {
      return this.input.generators.filter((e) => ["RENEWABLE", "HYDRO"].includes(e.type));
    },
    projectSettings() {
      return this.settings.STACKED_SERVICES_EMULATOR;
    },
    sequencingIsActive() {
      return this.projectSettings.sequencing && this.projectSettings.sequencing.active;
    },
  },
  data() {
    return {
      CaseModel,
    };
  },
  methods: {},
};
</script>

<style></style>
