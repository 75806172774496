// Charts depend on a num_years prop existing in the component using the chart
// Currently that means CaseOutput.vue

const chartUtils = {
  methods: {
    dygraphDispatch(objs /* , name = 'name' */) {
      if (objs == null) return null;
      const yearlyData = this.$utils.range(this.num_years).map((year) =>
        objs.map((e) => {
          const value = this.loadProfileForValue(e.value);
          return { ...e, value: value[year] };
        })
      );
      return yearlyData;
    },
    generatorDispatch(objs) {
      const dataYearly = [];
      const mapProfile = (year) => (e) => {
        const id = e.value.profile[year];
        let data = this.$store.getters["output-profiles/get"](id);
        if (data == null) {
          data = this.$store.dispatch("output-profiles/get", id);
        }
        return {
          data: data.profile,
          label: e.name,
          borderColor: this.$utils.getChartColor((Math.random() * 10).toFixed(0)),
          fill: false,
          borderWidth: 2,
          pointRadius: 0,
          hidden: true,
        };
      };

      for (let year = 0; year < this.num_years; year += 1) {
        const datasets = objs.map(mapProfile(year));

        dataYearly.push({
          datasets,
          labels: this.$utils.range(8760),
        });
      }
      return {
        type: "line",
        data: dataYearly,
        options: {
          // Attempts at performance Optimization
          tooltips: {
            enabled: false,
          },
          // showLines: false,
          elements: {
            line: {
              tension: 0, // disables bezier curves
            },
          },
          hover: {
            animationDuration: 0, // duration of animations when hovering an item
          },
          responsiveAnimationDuration: 0, // animation duration after a resize
          // /End Attempts
          responsive: true,
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Hour",
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: `${this.profileType} (MW)`,
              },
            },
          ],
          plugins: {
            datalabels: {
              display: false,
            },
          },
        },
      };
    },

    pieObjectByField(type = "pie", objs, field, valueKey = "value") {
      const groupData = objs; // Object.values(objs.reduce(this.groupBy(field), {}));
      const sum = (a, e) => a + e;
      if (!groupData.length) return null;

      // Make num_years copies of the data
      const dataYearly = [];
      for (let year = 0; year < this.num_years; year += 1) {
        // clone it so no weirdness happens
        const dataset = this.$utils.deepClone(groupData);
        // Map this year's data
        const data = dataset.map((e) =>
          Array.isArray(e[valueKey][year]) ? e[valueKey][year].reduce(sum, 0) : e[valueKey][year]
        );
        // Compute proportion
        const total = data.reduce(sum, 0);
        const proportion = data.map((e) => ((e / total) * 100).toFixed(1));
        // Set labels
        const labels = dataset.map((e, i) => `${e[field]} - ${proportion[i]}%`);

        dataYearly.push({
          labels,
          datasets: [
            {
              data,
              proportion,
              borderColor: this.$utils.chartColors,
              backgroundColor: this.$utils.chartColors,
              pointRadius: 0,
            },
          ],
        });
      }
      return {
        type,
        data: dataYearly,
        options: {
          responsive: true,
          legend: {
            position: "left",
          },
          tooltips: {
            callbacks: {},
          },
          plugins: {
            datalabels: {
              color: "white",
              formatter(val, context) {
                const index = context.dataIndex;
                const proportion = context.dataset.proportion[index];
                return `${proportion}%`;
              },
            },
          },
        },
      };
    },
  },
};

export default chartUtils;
