<template>
  <v-sheet elevation="2">
    <slot name="header" v-if="showHeader">
      <v-toolbar flat color="white">
        <v-toolbar-title v-if="title" class="subheading">{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <Dialog
          v-if="chart"
          icon="insert_chart"
          tooltip="Show Chart"
          :title="title"
          :body="chart"
        ></Dialog>
      </v-toolbar>
    </slot>
    <v-data-table
      v-bind="$attrs"
      :headers="yearlyHeaders"
      :items="tableItems"
      item-key="index"
      hide-default-footer
      disable-pagination
    >
      <template #item="props">
        <tr>
          <td class="">
            <v-btn icon @click="props.expand(!props.isExpanded)">
              <v-icon v-text="props.isExpanded ? 'expand_less' : 'expand_more'"></v-icon>
            </v-btn>
          </td>
          <td v-for="h in headers" :key="`${h.text}_${props.index}`">
            <span v-if="h.parseEnum" style="vertical-align: super">
              {{ props.item[h.value] | replaceUnderscore | toProperCase }}
            </span>
            <span v-else style="vertical-align: super">
              {{ props.item[h.value] }}
            </span>
          </td>
          <td v-for="i in yearCount" :key="`${i}_${props.index}`">
            {{ transformValue(props.item[valueKey][i - 1], props.item.precision) }}
          </td>
        </tr>
      </template>
      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-1">
          <Chart type="line" :data="chartData(item)" :options="chartOptions"></Chart>
        </td>
      </template>
    </v-data-table>
  </v-sheet>
</template>

<script>
export default {
  name: "DataTableYearly",
  components: {
    Dialog: () => import("@/components/Dialog"),
    Chart: () => import("@/components/charts/ChartJs"),
  },
  props: {
    headers: {
      type: Array,
      required: false,
      default: () => [{ text: "Name", value: "name" }],
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    itemKey: {
      type: String,
      required: false,
      default: "key",
    },
    valueKey: {
      type: String,
      required: false,
      default: "value",
    },
    num_years: {
      type: Number,
      required: false,
      default: null,
    },
    precision: {
      type: Number,
      required: false,
      default: 1,
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    showHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    chart: {
      type: Object,
      required: false,
      default: () => null,
    },
  },
  computed: {
    tableItems() {
      return this.items
        .filter((item) => item[this.valueKey] != null)
        .map((item, index) => ({ ...item, index }));
    },
    yearCount() {
      if (this.num_years) return this.num_years;
      if (this.tableItems.length) {
        const c = Object.values(this.tableItems[0][this.valueKey]).length;
        return c;
      }
      return 2;
    },
    yearlyHeaders() {
      const { headers } = this;
      // if (this.items[0].name == null) headers.shift();
      return [
        { value: "data-table-expand", sortable: false, width: 20 },
        ...headers,
        ...Array.from(Array(this.yearCount).keys()).map((e) => ({
          text: `Year ${e + 1}`,
          value: `value[${e}]`,
        })),
      ];
    },
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Year",
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: `${this.title}`,
              },
            },
          ],
        },
        zoom: {
          enabled: false,
          mode: "xy",
        },
        pan: {
          enabled: false,
          mode: "xy",
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
      },
    };
  },

  methods: {
    chartData(item) {
      const data = Object.values(item[this.valueKey])
        .slice(0, this.yearCount)
        .map((e) => this.transformValue(e, item.precision));
      const color = this.$utils.getChartColor((Math.random() * 10).toFixed(0));
      return {
        labels: [...data.keys()].map((e) => e + 1),
        datasets: [
          {
            data,
            label: item.name,
            fill: false,
            borderColor: color,
            backgroundColor: color,
            // pointRadius: 0,
          },
        ],
      };
    },
    // Can be passed a 'profile', which needs to be aggregated
    transformValue(value, precision = this.precision) {
      let result = value;
      if (Array.isArray(value)) {
        if (typeof value[0] === "number") {
          result = value.reduce((a, e) => a + e);
        }
      }
      return typeof result === "number" ? result.toFixed(precision) : result;
    },
  },
  created() {},
};
</script>

<style></style>
