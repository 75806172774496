<template>
  <div>
    <v-toolbar color="primary" dark tabs flat dense>
      <v-app-bar-nav-icon @click="$emit('toggle-drawer')"></v-app-bar-nav-icon>

      <v-toolbar-title class="ml-2">Outputs: Tabular</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="downloadOutputs(currentCase.id)">
        <v-icon>get_app</v-icon>
      </v-btn>
      <template #extension>
        <v-tabs v-if="pages" v-model="tabRef" background-color="primary" grow show-arrows>
          <v-tabs-slider color="accent"></v-tabs-slider>
          <v-tab v-for="page in pages" :key="page.title">{{ page.title }}</v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-expand-transition v-if="loadPending">
      <v-dialog :value="loadPending" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Loading...
            <v-progress-linear indeterminate color="white"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-expand-transition>
    <v-tabs-items v-if="pages" v-model="tabRef">
      <v-tab-item v-for="page in pages" :key="page.title">
        <CardSimple v-for="section in page.sections" :key="section.title" :title="section.title">
          <v-tabs v-if="section.tabs">
            <template v-for="tab in section.tabs">
              <v-tab
                :key="tab.name"
                :href="'#' + $utils.kebabCase(page.title + section.title + tab.name)"
                >{{ tab.name }}</v-tab
              >
              <v-tab-item
                :key="tab.name"
                :value="$utils.kebabCase(page.title + section.title + tab.name)"
                lazy
              >
                <OutputSection :section="section"></OutputSection>
              </v-tab-item>
            </template>
          </v-tabs>
          <OutputSection v-else :section="section"></OutputSection>
        </CardSimple>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { makeFindMixin } from "feathers-vuex";

import outputs from "@/mixins/outputs";
import fieldUtils from "@/mixins/fieldUtils";
import chartUtils from "@/mixins/chartUtils";

export default {
  name: "CaseOutput",
  mixins: [
    fieldUtils,
    chartUtils,
    outputs,
    makeFindMixin({
      service: "outputs",
      queryWhen: "caseId",
      watch: "params",
      fetchParams: "outputsFetchParams",
    }),
    /* makeFindMixin({
      service: 'output-profiles',
      queryWhen: true,
      watch: 'params',
    }), */
  ],
  components: {
    CardSimple: () => import("@/components/CardSimple.vue"),
    OutputSection: () => import("@/components/OutputSection.vue"),
  },
  props: {
    currentCase: Object,
    num_years: Number,
  },
  data() {
    return {
      tabRef: 0,
      job_id: null,
      generatorHeaders: [
        { text: "Name", value: "name" },
        { text: "Type", value: "type", parseEnum: true },
        { text: "Source", value: "source", parseEnum: true },
      ],
      storageHeaders: [
        { text: "Name", value: "name" },
        { text: "Source", value: "source", parseEnum: true },
      ],
      projectHeaders: [
        { text: "Name", value: "name" },
        { text: "Node ID", value: "PTID" },
        { text: "Power", value: "power" },
        { text: "Duration", value: "duration" },
      ],
    };
  },
  computed: {
    ...mapState({}),
    ...mapGetters({
      jobLoadPending: "jobs/loadPending",
      outputLoadPending: "outputs/loadPending",
      outputProfileLoadPending: "output-profiles/loadPending",
    }),
    loadPending() {
      return (
        // this.jobLoadPending ||
        this.outputLoadPending ||
        this.outputProfileLoadPending ||
        this.pages == null ||
        this.pages.length === 0
      );
    },
    caseId() {
      return this.currentCase ? this.currentCase.id : null;
    },
    outputsParams() {
      return {
        query: {
          case_id: this.caseId,
        },
      };
    },
    outputsFetchParams() {
      return {
        query: {
          case_id: this.caseId,
          $select: ["id", "case_id", "output"],
        },
      };
    },
    outputProfilesParams() {
      return {
        query: {
          case_id: this.caseId,
        },
      };
    },
    reserves() {
      return this.$utils.getIfExists(this.input, ["reserves"], []);
    },
    input() {
      if (this.currentCase && this.currentCase.input) {
        return this.currentCase.input;
      }
      return null;
    },
    output() {
      return this.outputs.reduce((a, e) => {
        // TODO: reassess why this side-effect is even here
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.job_id = e.id;
        console.debug("job", e);
        const res = a;
        res[e.type_name] = e.output;
        return res;
      }, {});
    },
    pages() {
      if (!this.input || !this.output) return null;
      console.debug("input", this.input);
      console.debug("output", this.output);

      const pages = [];
      if (this.output.ALTERNATIVE_ANALYSIS) pages.push(this.AAOutput);

      if (this.output.PRODUCTION_COST) pages.push(this.PCOutput);

      if (this.output.STACKED_SERVICES_EMULATOR) pages.push(this.SSEOutput);

      const result = Object.values(pages);
      console.debug("page", result);
      return result;
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      downloadOutputs: "cases/downloadOutputs",
      loadOutputProfiles: "output-profiles/find",
    }),
    /**
     * Creates an array of objects
     * Each object has the identifying properties of the input, plus a 'value' property
     * The value property == outputObj[field], where the outputObj matches the input object
     */
    mergeObjectField(config, objects, field, headers = [{ value: "name" }]) {
      const defaultConfig = { inputId: "id", outputId: "id" };
      const { inputSource, outputSource, inputId, outputId } = {
        ...defaultConfig,
        ...config,
      };
      let objectTypes = this.$utils.deepClone(objects);
      if (!Array.isArray(objectTypes)) {
        objectTypes = [objectTypes];
      }
      // Allow multiple types in one call (generators, storage, etc.)
      return (
        objectTypes
          .map((type) => {
            const inputObjs = inputSource[type];
            const outputObjs = outputSource[type];
            if (inputObjs == null) {
              throw Error(`Invalid inputObjs at mergeObjectField. Field: ${field}`);
            }
            if (outputObjs == null) {
              throw Error(`Invalid outputObjs at mergeObjectField. Field: ${field}`);
            }

            return (
              outputObjs
                // Filters out non-thermal generators for thermal generator-only fields
                .filter((output) => output[field] != null)
                .map((output) => {
                  // inputObjs tend to have identifiers (name, type, source) that the outputObj needs
                  let input = inputObjs.find((i) => i[inputId] === output[outputId]);

                  // TODO: Remove the find failsafe. Dependent on proper id passthrough
                  if (input == null) {
                    input = inputObjs[output[outputId]];
                  }

                  // the table headers are typically passed, which don't include the object's ID

                  const result = {
                    id: input.id,
                  };
                  headers
                    // Sometimes an object won't have certain headers
                    // ex: demand resposnse doesn't have a type or source
                    // Sometimes an output will have the header, but not the input
                    // ex: Project outputs have a PTID
                    .filter((header) => input[header.value] != null || output[header.value] != null)
                    .forEach((header) => {
                      result[header.value] = input[header.value] || output[header.value];
                    });
                  result.value = output[field];

                  return result;
                })
            );
          })
          // Without this the first dimension of the array aligns with objects
          .flat()
      );
    },
    mapValue(source) {
      return (item) => ({ ...item, value: source[item.value] });
    },
    mapSumValues(el) {
      return {
        ...el,
        value: el.value.map((year) => {
          if (Array.isArray(year)) {
            return year.reduce((a, e) => a + e);
          }
          return year;
        }),
      };
    },
  },
};
</script>

<style></style>
