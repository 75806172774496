import DataTableYearly from "@/components/DataTableYearly";
import ChartYearly from "@/components/charts/ChartYearly";
import ChartStacked from "@/components/charts/ChartStacked";
import Plotly from "@/components/charts/Plotly";

export default {
  computed: {
    capacityRequirement() {
      if (this.input == null) return null;
      return this.$utils
        .range(this.num_years)
        .map((idx) => this.input.demand_peak[idx] * (1 + this.input.capacity_margin[idx]));
    },
    inputCard() {
      return {
        title: "Inputs",
        children: [
          {
            title: "Demand (Input)",
            component: DataTableYearly,
            props: {
              itemKey: "name",
              items: [
                { name: "Peak", value: "demand_peak" },
                { name: "Energy", value: "demand_annual" },
              ].map(this.mapValue(this.input)),
            },
          },
          {
            title: "Capacity Requirement (Calculation)",
            component: DataTableYearly,
            props: {
              itemKey: "name",
              items: [
                {
                  name: "Installed Capacity Requirement",
                  value: this.capacityRequirement,
                },
              ],
            },
          },
        ],
      };
    },
    AAOutput() {
      const outputs = this.output.ALTERNATIVE_ANALYSIS;
      const mapValue = this.mapValue(outputs);
      /* const mergeObjectField = (
        objects,
        field,
        headers = this.generatorHeaders
      ) =>
        this.mergeObjectField(
          { inputSource: this.input, outputSource: outputs },
          objects,
          field,
          headers
        ); */

      const page = {
        title: "Alternative Analysis",
        sections: [
          this.inputCard,
          {
            title: "Installed Capacity",
            children: [
              {
                title: "Thermal Capacity (MW)",
                component: DataTableYearly,
                props: {
                  headers: this.generatorHeaders,
                  valueKey: "installed_capacity",
                  items: outputs.generators.filter((e) => e.type === "THERMAL"),
                },
                chart: {
                  component: ChartStacked,
                  props: {
                    type: "line",
                    valueKey: "installed_capacity",
                    items: outputs.generators.filter((e) => e.type === "THERMAL"),
                    yAxisLabel: "Capacity (MW)",
                    num_years: this.num_years,
                  },
                },
              },
              {
                title: "Renewable Capacity (MW)",
                component: DataTableYearly,
                props: {
                  headers: this.generatorHeaders,
                  valueKey: "installed_capacity",
                  items: outputs.generators.filter((e) => e.type === "RENEWABLE"),
                },
                chart: {
                  component: ChartStacked,
                  props: {
                    type: "line",
                    valueKey: "installed_capacity",
                    items: outputs.generators.filter((e) => e.type === "RENEWABLE"),
                    yAxisLabel: "Capacity (MW)",
                    num_years: this.num_years,
                  },
                },
              },
              {
                title: "Hydro Capacity (MW)",
                component: DataTableYearly,
                props: {
                  headers: this.generatorHeaders,
                  valueKey: "installed_capacity",
                  items: outputs.generators.filter((e) => e.type === "HYDRO"),
                },
                chart: {
                  component: ChartStacked,
                  props: {
                    type: "line",
                    valueKey: "installed_capacity",
                    items: outputs.generators.filter((e) => e.type === "HYDRO"),
                    yAxisLabel: "Capacity (MW)",
                    num_years: this.num_years,
                  },
                },
              },
              {
                title: "Storage Capacity (MW, MWh)",
                children: [
                  {
                    component: DataTableYearly,
                    props: {
                      showHeader: true,
                      title: "Power Capacity (MW)",
                      headers: this.storageHeaders,
                      valueKey: "installed_capacity",
                      items: outputs.storage,
                      chart: {
                        component: ChartStacked,
                        props: {
                          type: "line",
                          valueKey: "installed_capacity",
                          items: outputs.storage,
                          yAxisLabel: "Capacity (MW)",
                          num_years: this.num_years,
                        },
                      },
                    },
                  },
                  {
                    component: DataTableYearly,
                    props: {
                      showHeader: true,
                      title: "Energy Capacity (MWh)",
                      headers: this.storageHeaders,
                      valueKey: "energy_capacity",
                      items: outputs.storage,
                    },
                  },
                ],
              },
              {
                title: "Demand Response Capacity (MW)",
                component: DataTableYearly,
                props: {
                  valueKey: "installed_capacity",
                  items: outputs.demand_response,
                },
                chart: {
                  component: ChartStacked,
                  props: {
                    type: "line",
                    valueKey: "installed_capacity",
                    items: outputs.demand_response,
                    yAxisLabel: "Capacity (MW)",
                    num_years: this.num_years,
                  },
                },
              },
              {
                title: "Energy Efficiency Capacity (MW)",
                component: DataTableYearly,
                props: {
                  valueKey: "installed_capacity",
                  items: outputs.energy_efficiency,
                },
                chart: {
                  component: ChartStacked,
                  props: {
                    type: "line",
                    valueKey: "installed_capacity",
                    items: outputs.energy_efficiency,
                    yAxisLabel: "Capacity (MW)",
                    num_years: this.num_years,
                  },
                },
              },

              {
                title: "Total",
                component: DataTableYearly,
                props: {
                  itemKey: "name",
                  items: [
                    { name: "Firm Capacity (MW)", value: "firm_capacity" },
                    {
                      name: "Total Installed Capacity (MW)",
                      value: "installed_capacity",
                    },
                    { name: "Capacity Margin (%)", value: "capacity_margin" },
                    { name: "Objective Value ($)", value: "objective_value" },
                  ].map(mapValue),
                },
              },
            ],
          },
          {
            title: "Capacity by Fuel Type",
            children: [
              {
                title: "Capacity by Fuel Type (MW)",
                component: DataTableYearly,
                props: {
                  headers: [
                    { text: "Type", value: "type" },
                    // { text: 'Source', value: 'source' },
                  ],
                  items: outputs.fuel_types,
                  itemKey: "type",
                  valueKey: "result_capacity",
                },
                chart: [
                  {
                    title: "Pie",
                    component: ChartYearly,
                    props: {
                      ...this.pieObjectByField(
                        "doughnut",
                        outputs.fuel_types,
                        "type",
                        "result_capacity"
                      ),
                    },
                  },
                  {
                    title: "Stacked Area",
                    component: ChartStacked,
                    props: {
                      type: "line",
                      nameKey: "type",
                      valueKey: "result_capacity",
                      items: outputs.fuel_types,
                      yAxisLabel: "Capacity (MW)",
                      num_years: this.num_years,
                    },
                  },
                ],
              },
              {
                title: "Energy by Fuel Type (MWh)",
                component: DataTableYearly,
                props: {
                  headers: [
                    { text: "Type", value: "type" },
                    // { text: 'Source', value: 'source' },
                  ],
                  items: outputs.fuel_types,
                  itemKey: "type",
                  valueKey: "generation",
                },
                chart: [
                  {
                    title: "Pie",
                    component: ChartYearly,
                    props: {
                      ...this.pieObjectByField(
                        "doughnut",
                        outputs.fuel_types,
                        "type",
                        "generation"
                      ),
                    },
                  },
                  {
                    title: "Stacked Area",
                    component: ChartStacked,
                    props: {
                      type: "line",
                      nameKey: "type",
                      valueKey: "generation",
                      items: outputs.fuel_types,
                      yAxisLabel: "Capacity (MW)",
                      num_years: this.num_years,
                    },
                  },
                ],
              },
            ],
          },
          {
            title: "Planned Retirements and Builds",
            children: [
              {
                title: "Deactivation (MW)",
                component: DataTableYearly,
                props: {
                  headers: this.generatorHeaders,
                  valueKey: "forced_deactivation",
                  items: outputs.generators,
                },
              },
              {
                title: "Planned Build (MW)",
                component: DataTableYearly,
                props: {
                  headers: this.generatorHeaders,
                  valueKey: "forced_build",
                  items: outputs.generators,
                },
              },
            ],
          },
          {
            title: "Economic Retirements and Builds",
            children: [
              {
                title: "Economic Retirement (MW)",
                component: DataTableYearly,
                props: {
                  headers: this.generatorHeaders,
                  valueKey: "economic_retirement",
                  items: [
                    ...outputs.generators,
                    ...outputs.storage,
                    // ...outputs.demand_response,
                    // ...outputs.energy_efficiency,
                  ],
                },
              },
              {
                title: "Economic Build (MW)",
                component: DataTableYearly,
                props: {
                  headers: this.generatorHeaders,
                  valueKey: "economic_build",
                  items: [
                    ...outputs.generators,
                    ...outputs.storage,
                    // ...outputs.demand_response,
                    // ...outputs.energy_efficiency,
                  ],
                },
              },
            ],
          },
          {
            title: "Forced Deactivation and Builds",
            children: [
              {
                title: "Forced Deactivation (MW)",
                component: DataTableYearly,
                props: {
                  headers: this.generatorHeaders,
                  valueKey: "forced_deactivation",
                  items: [
                    ...outputs.generators,
                    ...outputs.storage,
                    // ...outputs.demand_response,
                    // ...outputs.energy_efficiency,
                  ],
                },
              },
              {
                title: "Forced Build (MW)",
                component: DataTableYearly,
                props: {
                  headers: this.generatorHeaders,
                  valueKey: "forced_build",
                  items: [
                    ...outputs.generators,
                    ...outputs.storage,
                    // ...outputs.demand_response,
                    // ...outputs.energy_efficiency,
                  ],
                },
              },
            ],
          },
          {
            title: "Operational Costs",
            children: [
              {
                title: "Fuel Costs ($)",
                component: DataTableYearly,
                props: {
                  headers: this.generatorHeaders,
                  valueKey: "fuel_cost",
                  items: outputs.generators,
                },
              },
              {
                title: "Emissions Costs ($)",
                component: DataTableYearly,
                props: {
                  headers: this.generatorHeaders,
                  valueKey: "emissions_cost",
                  items: outputs.generators,
                },
              },
              {
                title: "VOM Cost ($)",
                component: DataTableYearly,
                props: {
                  headers: this.generatorHeaders,
                  valueKey: "vom_cost",
                  items: [
                    ...outputs.generators,
                    ...outputs.storage,
                    // ...outputs.demand_response,
                    // ...outputs.energy_efficiency,
                  ],
                },
              },
              {
                title: "FOM Cost ($)",
                component: DataTableYearly,
                props: {
                  headers: this.generatorHeaders,
                  valueKey: "fom_cost",
                  items: [
                    ...outputs.generators,
                    ...outputs.storage,
                    // ...outputs.demand_response,
                    // ...outputs.energy_efficiency,
                  ],
                },
              },
              {
                title: "Operational Cost ($)",
                component: DataTableYearly,
                props: {
                  headers: this.generatorHeaders,
                  valueKey: "operational_cost",
                  items: [
                    ...outputs.generators,
                    ...outputs.storage,
                    // ...outputs.demand_response,
                    // ...outputs.energy_efficiency,
                  ],
                },
              },
              {
                title: "Renewable Energy Curtailment (GWh)",
                component: DataTableYearly,
                props: {
                  headers: this.generatorHeaders,
                  valueKey: "energy_curtailment",
                  items: outputs.generators.filter((e) => e.type === "RENEWABLE"),
                },
              },
              {
                title: "Lost Load (MWh)",
                component: DataTableYearly,
                props: {
                  items: [
                    {
                      value: Object.values(outputs.lost_load).map((y) =>
                        y.reduce((acc, i) => acc + i, 0)
                      ),
                    },
                  ],
                },
              },
              {
                title: "Totals",
                component: DataTableYearly,
                props: {
                  itemKey: "name",
                  items: [
                    { name: "Zonal Price ($/MWh)", value: "zonal_price" },
                    {
                      name: "Capacity Price ($/kW-yr)",
                      value: "capacity_price",
                    },
                    { name: "CO2 Emission (tons)", value: "co2_emissions" },
                    { name: "Emissions Cost ($)", value: "co2_emissions_cost" },
                    { name: "Generation Cost ($)", value: "generation_cost" },
                    { name: "FOM Cost ($)", value: "fom_cost" },
                    { name: "VOM Cost ($)", value: "vom_cost" },
                    { name: "Fuel Cost ($)", value: "fuel_cost" },
                    {
                      name: "Renewable Energy Curtailment (TWh)",
                      value: "renewable_cutailment",
                    },
                  ].map(mapValue),
                },
              },
              {
                title: "Graph",
                component: ChartStacked,
                props: {
                  type: "bar",
                  items: [
                    { name: "FOM Cost ($)", value: "fom_cost" },
                    { name: "VOM Cost ($)", value: "vom_cost" },
                    { name: "Emissions Cost ($)", value: "co2_emissions_cost" },
                    { name: "Fuel Cost ($)", value: "fuel_cost" },
                  ].map(mapValue),
                  yAxisLabel: "Cost ($)",
                  num_years: this.num_years,
                },
              },
            ],
          },
          {
            title: "Capital Costs",
            children: [
              {
                title: "Overnight Costs ($)",
                component: DataTableYearly,
                props: {
                  headers: this.generatorHeaders,
                  valueKey: "overnight_costs",
                  items: [...outputs.generators, ...outputs.storage, ...outputs.energy_efficiency],
                },
              },
              {
                title: "Annuities ($)",
                component: DataTableYearly,
                props: {
                  headers: this.generatorHeaders,
                  valueKey: "annuities",
                  items: [...outputs.generators, ...outputs.storage, ...outputs.energy_efficiency],
                },
              },
              {
                title: "Totals",
                component: DataTableYearly,
                props: {
                  itemKey: "name",
                  items: [
                    { name: "Overnight Costs", value: "total_overnight" },
                    { name: "Annuities", value: "total_annuities" },
                  ].map(mapValue),
                },
              },
            ],
          },
        ],
      };
      return page;
    },
    PCOutput() {
      const outputs = this.output.PRODUCTION_COST;
      const mapValue = this.mapValue(outputs);
      const mergeObjectField = (objects, field, headers = this.generatorHeaders) =>
        this.mergeObjectField(
          { inputSource: this.input, outputSource: outputs },
          objects,
          field,
          headers
        );

      const page = {
        title: "Production Cost",
        sections: [
          this.inputCard,
          /* {
            title: 'Installed Capacity',
            children: [],
          },
          {
            title: 'Capacity by Fuel Type',
            children: [],
          },
          {
            title: 'Operational Costs',
            children: [],
          }, */
          {
            title: "Metrics",
            children: [
              {
                title: "Fuel Burn (GJ)",
                component: DataTableYearly,
                props: {
                  headers: this.generatorHeaders,
                  valueKey: "fuel_burn",
                  items: outputs.generators,
                },
              },
              {
                title: "Fuel Cost ($)",
                component: DataTableYearly,
                props: {
                  headers: this.generatorHeaders,
                  valueKey: "fuel_cost",
                  items: outputs.generators,
                },
              },
              {
                title: "VOM Cost ($)",
                component: DataTableYearly,
                props: {
                  headers: this.generatorHeaders,
                  valueKey: "vom_cost",
                  items: [...outputs.generators, ...outputs.storage, ...outputs.demand_response],
                },
              },
              {
                title: "CO2 Emissions (Mtonne)",
                component: DataTableYearly,
                props: {
                  headers: this.generatorHeaders,
                  valueKey: "co2_emissions",
                  items: outputs.generators,
                },
              },
              {
                title: "CO2 Emission Cost ($)",
                component: DataTableYearly,
                props: {
                  headers: this.generatorHeaders,
                  valueKey: "co2_cost",
                  items: outputs.generators,
                },
              },
              {
                title: "Energy Sales ($)",
                component: DataTableYearly,
                props: {
                  headers: this.generatorHeaders,
                  valueKey: "energy_revenue",
                  items: [...outputs.generators, ...outputs.storage],
                },
              },
              {
                title: "Renewable Energy Curtailment (GWh)",
                component: DataTableYearly,
                props: {
                  headers: this.generatorHeaders,
                  valueKey: "energy_curtailment",
                  items: outputs.generators.filter((e) => e.type === "RENEWABLE"),
                },
              },
              ...this.reserves.map((reserve) => ({
                title: `${reserve.name} Reserve Sales ($)`,
                component: DataTableYearly,
                props: {
                  headers: this.generatorHeaders,
                  items: mergeObjectField(
                    ["generators", "storage"],
                    "reserves",
                    this.generatorHeaders
                  ).map(this.mapObjectId(reserve.id, "revenue")),
                },
              })),
              {
                title: "FOM Cost ($)",
                component: DataTableYearly,
                props: {
                  headers: this.generatorHeaders,
                  valueKey: "fom_cost",
                  items: [...outputs.generators, ...outputs.storage, ...outputs.demand_response],
                },
              },
              ...this.reserves.map((reserve) => ({
                title: `${reserve.name} Reserve Cost ($)`,
                component: DataTableYearly,
                props: {
                  headers: this.generatorHeaders,
                  items: mergeObjectField(["generators", "storage"], "reserves").map(
                    this.mapObjectId(reserve.id, "cost")
                  ),
                },
              })),
              // TODO: FIX
              {
                title: "Energy Price ($/MWh)",
                component: Plotly,
                props: {
                  data: [
                    {
                      y: Object.values(outputs.zonal_price),
                      x: Object.keys(outputs.zonal_price),
                    },
                  ],
                },
              },
              {
                title: "Energy by Fuel Type (MWh)",
                component: DataTableYearly,
                props: {
                  headers: [
                    { text: "Type", value: "type" },
                    { text: "Source", value: "source" },
                  ],
                  items: Object.values(
                    mergeObjectField("generators", "energy")
                      .map((generator) => ({
                        ...generator,
                        value: Object.values(generator.value.profile)
                          .map((id) => {
                            const data = this.retrieveOutputProfile(id);
                            if (data.length) {
                              return data;
                            }
                            return "[]";
                          })
                          .reduce(
                            (arr, val, i) => ({
                              ...arr,
                              [i]: typeof val === "string" ? JSON.parse(val) : val,
                            }),
                            {}
                          ),
                      }))
                      .reduce(this.groupBy("source"), {})
                  ),
                },
              },
              {
                title: "Lost Load (MWh)",
                component: DataTableYearly,
                props: {
                  items: [{ value: Object.values(outputs.lost_load.profile) }],
                },
              },
              {
                title: "General",
                component: DataTableYearly,
                props: {
                  itemKey: "name",
                  items: [
                    {
                      name: "Zonal Price ($/MWh)",
                      value: "zonal_price",
                    },
                    {
                      name: "CO2 Emissions (tons)",
                      value: "co2_emissions",
                    },
                    {
                      name: "Emissions Cost ($)",
                      value: "co2_emissions_cost",
                    },
                    {
                      name: "Generation Cost ($)",
                      value: "generation_cost",
                    },
                    {
                      name: "VOM Cost ($)",
                      value: "vom_cost",
                    },
                    {
                      name: "FOM Cost ($)",
                      value: "fom_cost",
                    },
                    {
                      name: "Cost to Load ($)",
                      value: "load_cost",
                    },
                    {
                      name: "Total Fuel Cost ($)",
                      value: "fuel_cost",
                    },
                    {
                      name: "Objective Value ($)",
                      value: "objective_value",
                    },
                    {
                      name: "Renewable Energy Curtailment (TWh)",
                      value: "renewable_cutailment",
                    },
                  ].map(mapValue),
                },
              },
              /* {
                title: 'Generation Dispatch by Technology',
                component: ChartYearly,
                props: this.pieObjectByField(
                  'doughnut',
                  mergeObjectField('generators', 'energy').map(obj =>
                    ({...obj, value:this.loadProfileForValue(obj.value) })
                  ),
                  'source'
                ),
              },
              {
                title: 'Generation Dispatch',
                component: ChartYearly,
                props: {
                  chartComponent: ChartLine,
                  type: 'line',
                  data: this.dygraphDispatch(
                    mergeObjectField('generators', 'energy')
                  ),
                  options: {
                    legend: 'always',
                    ylabel: '$/MW',
                    showRangeSelector: true,
                    stackedGraph: true,
                  },
                },
              }, */
            ],
          },
        ],
      };
      return page;
    },

    SSEOutput() {
      const outputs = this.output.STACKED_SERVICES_EMULATOR;
      const input = { nodes: this.input.projects };
      const page = {
        title: "Stacked Services Emulator",
        sections: [],
      };
      const section = { title: "Metrics" };
      // If Standard Output
      // section > children
      if (outputs.nodes) {
        section.children = this.SSESections(input, outputs);
      } else {
        // Else tabbed output
        // section > tabs > children
        const outputTabs = Object.values(outputs);
        section.tabs = outputTabs.map((output) => ({
          name: output.name,
          children: this.SSESections(input, output),
        }));
      }
      page.sections.push(section);
      return page;
    },
  },
  methods: {
    SSESections(input, output) {
      // const mapValue = this.mapValue(output);
      const mergeObjectField = (objects, field) =>
        this.mergeObjectField(
          {
            inputSource: input,
            outputSource: output,
            inputId: "name",
            outputId: "name",
          },
          objects,
          field,
          this.projectHeaders
        );
      return [
        {
          title: "Energy Sales ($)",
          component: DataTableYearly,
          props: {
            headers: this.projectHeaders,
            valueKey: "energy_revenue",
            items: output.nodes,
          },
        },
        ...this.reserves.map((reserve) => ({
          title: `${reserve.name} Reserve Sales ($)`,
          component: DataTableYearly,
          props: {
            headers: this.projectHeaders,
            items: (() => {
              const merged = mergeObjectField("nodes", "reserves");
              if (merged.reserves) {
                const mapped = merged.map(this.mapObjectId(reserve.id, "revenue"));
                return mapped;
              }
              return [];
            })(),
          },
        })),
        {
          title: "Renewable Shifting Sales ($)",
          component: DataTableYearly,
          props: {
            headers: this.projectHeaders,
            valueKey: "renewable_shifting_revenue",
            items: output.nodes,
          },
        },
        {
          title: "Peak Shaving Energy (GWh)",
          component: DataTableYearly,
          props: {
            headers: this.projectHeaders,
            valueKey: "peak_shaving_energy",
            items: output.nodes,
          },
        },
        {
          title: "Annual Profit ($)",
          component: DataTableYearly,
          props: {
            headers: this.projectHeaders,
            valueKey: "annual_value",
            items: output.nodes,
          },
        },
      ];
    },
  },
};
