/* eslint-disable no-param-reassign */
const fieldUtils = {
  methods: {
    retrieveOutputProfile(id) {
      const data = this.$store.getters["output-profiles/get"](id);
      if (data && data.profile) return data.profile;
      return this.$store.dispatch("output-profiles/get", id).then((res) => res.profile);
    },
    loadProfileForValue(obj) {
      const { profile } = obj;
      const results = Object.entries(profile).reduce((acc, [year, id]) => {
        acc[year] = this.retrieveOutputProfile(id);

        return acc;
      }, {});
      return { ...results, profile };
    },
    mapToValue(field) {
      return (e) => {
        const res = this.$utils.deepClone(e);
        res.value = res[field];
        return res;
      };
    },
    groupBy(field) {
      return (result, obj) => {
        const fieldValue = obj[field];
        if (result[fieldValue]) {
          result[fieldValue].value = Object.values(result[fieldValue].value).map((val, year) => {
            // if val is a profile
            if (Array.isArray(val)) {
              return val.map((e, i) => e + obj.value[year][i]);
            }
            return val + obj.value[year];
          });
        } else {
          const { type, source, value } = obj;
          result[fieldValue] = { type, source, value };
        }
        return result;
      };
    },
    mapObjectId(id, field) {
      return (obj) => {
        obj.value = obj.value.find((e) => e.id === id)[field];
        return obj;
      };
    },
  },
};

export default fieldUtils;
