<template>
  <v-row>
    <v-col>
      <Chart :type="type" :data="chartData" :options="chartOptions"></Chart>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ChartStacked",
  components: {
    Chart: () => import("@/components/charts/ChartJs"),
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    num_years: {
      type: Number,
      required: true,
    },
    yAxisLabel: {
      type: String,
      required: true,
    },
    visible: {
      type: Boolean,
      required: false,
      default: true,
    },
    nameKey: {
      type: String,
      required: false,
      default: "name",
    },
    valueKey: {
      type: String,
      required: false,
      default: "value",
    },
  },
  computed: {
    chartData() {
      return {
        labels: this.$utils.range(this.num_years, 1),
        datasets: this.items
          .filter((item) => item[this.valueKey] != null)
          .map((item, idx) => {
            const color = this.$utils.getChartColor(idx);
            // Outputs can return the value as an object
            const data = item[this.valueKey];
            const arrData = !Array.isArray(data) ? Object.values(data) : data;

            const slicedData = arrData.slice(0, this.num_years);
            return {
              label: item[this.nameKey],
              data: slicedData,
              // fill: false,
              borderColor: color,
              backgroundColor: color,
              // pointRadius: 0,
            };
          }),
      };
    },
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              return this.addCommas(value);
            },
          },
        },
        scales: {
          xAxes: [
            {
              stacked: this.type === "bar",
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Year",
                fontSize: 16,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: (value /* , index, values */) => this.addCommas(value),
              },
              stacked: true,
              display: true,
              scaleLabel: {
                display: true,
                labelString: this.yAxisLabel,
                fontSize: 16,
              },
            },
          ],
        },
        zoom: {
          enabled: false,
          mode: "xy",
        },
        pan: {
          enabled: false,
          mode: "xy",
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
      },
    };
  },
  methods: {
    addCommas(value) {
      const [whole, fractional] = value.toString().split(".");

      const result = whole.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if (parseInt(value, 10) >= 1000 && value.toString().includes(".")) {
        return `${result}.${fractional}`;
      }
      return result;
    },
  },
};
</script>

<style></style>
