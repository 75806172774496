<template>
  <v-row justify="center">
    <v-col cols="12" lg="8">
      <CardSimple title="General">
        <v-row>
          <v-col>
            <v-text-field
              v-model.number="settings.year_start"
              :label="schema.year_start.title"
              :hint="schema.year_start.tooltip"
              :error-messages="$utils.getIfExists(errors, ['settings', 'year_start', 'messages'])"
              type="number"
              @change="$emit('change')"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model.number="settings.year_end"
              :label="schema.year_end.title"
              :hint="schema.year_end.tooltip"
              :error-messages="$utils.getIfExists(errors, ['settings', 'year_end', 'messages'])"
              type="number"
              @change="$emit('change')"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </CardSimple>
      <CardSimple title="Alternative Analysis">
        <v-switch
          label="Enable MIP"
          hint="Binary Variables"
          persistent-hint
          v-model="settings.ALTERNATIVE_ANALYSIS.integer"
          :error-messages="
            $utils.getIfExists(errors, [
              'settings',
              'STACKED_SERVICES_EMULATOR',
              'integer',
              'messages',
            ])
          "
          :class="`my-${inputMargin}`"
          @change="$emit('change')"
        ></v-switch>
        <v-switch
          v-model="settings.ALTERNATIVE_ANALYSIS.output_formulation"
          :label="schema.ALTERNATIVE_ANALYSIS.properties.output_formulation.title"
          :hint="schema.ALTERNATIVE_ANALYSIS.properties.output_formulation.tooltip"
          persistent-hint
          :error-messages="
            $utils.getIfExists(errors, [
              'settings',
              'ALTERNATIVE_ANALYSIS',
              'output_formulation',
              'messages',
            ])
          "
          :class="`my-${inputMargin}`"
          @change="$emit('change')"
        ></v-switch>
        <v-slider
          v-model.number="settings.ALTERNATIVE_ANALYSIS.tolerance"
          :label="`Tolerance: 1e${settings.ALTERNATIVE_ANALYSIS.tolerance}`"
          :hint="schema.ALTERNATIVE_ANALYSIS.properties.tolerance.tooltip"
          :error-messages="
            $utils.getIfExists(errors, [
              'settings',
              'ALTERNATIVE_ANALYSIS',
              'tolerance',
              'messages',
            ])
          "
          max="-1"
          min="-38"
          always-dirty
          thumb-label="always"
          :class="`my-${inputMargin}`"
          @change="$emit('change')"
        ></v-slider>
        <v-text-field
          v-model.number="settings.ALTERNATIVE_ANALYSIS.timeout"
          :label="schema.ALTERNATIVE_ANALYSIS.properties.timeout.title"
          :hint="schema.ALTERNATIVE_ANALYSIS.properties.timeout.tooltip"
          :error-messages="
            $utils.getIfExists(errors, ['settings', 'ALTERNATIVE_ANALYSIS', 'timeout', 'messages'])
          "
          suffix="seconds"
          type="number"
          :class="`my-${inputMargin}`"
          @change="$emit('change')"
        ></v-text-field>
        <v-select
          v-model="settings.ALTERNATIVE_ANALYSIS.representative_days"
          :label="schema.ALTERNATIVE_ANALYSIS.properties.representative_days.title"
          :hint="schema.ALTERNATIVE_ANALYSIS.properties.representative_days.tooltip"
          persistent-hint
          :error-messages="
            $utils.getIfExists(errors, [
              'settings',
              'ALTERNATIVE_ANALYSIS',
              'representative_days',
              'messages',
            ])
          "
          :items="['Clustering', 'Renewable Variability', 'User Defined']"
          :class="`my-${inputMargin}`"
          @change="$emit('change')"
        ></v-select>
        <InputDateArray
          v-if="settings.ALTERNATIVE_ANALYSIS.representative_days === 'User Defined'"
          v-model="settings.ALTERNATIVE_ANALYSIS.chosen_days"
          :year="settings.year_start"
          :error-messages="
            $utils.getIfExists(errors, [
              'settings',
              'ALTERNATIVE_ANALYSIS',
              'chosen_days',
              'messages',
            ])
          "
          ordinal
          :class="`my-${inputMargin}`"
          @change="$emit('change')"
        >
        </InputDateArray>
        <v-slider
          v-if="settings.ALTERNATIVE_ANALYSIS.representative_days !== 'User Defined'"
          v-model.number="settings.ALTERNATIVE_ANALYSIS.cluster_days"
          :error-messages="
            $utils.getIfExists(errors, [
              'settings',
              'ALTERNATIVE_ANALYSIS',
              'cluster_days',
              'messages',
            ])
          "
          :label="schema.ALTERNATIVE_ANALYSIS.properties.cluster_days.title"
          :hint="schema.ALTERNATIVE_ANALYSIS.properties.cluster_days.tooltip"
          min="1"
          max="30"
          :class="`my-${inputMargin}`"
          @change="$emit('change')"
          thumb-label="always"
        ></v-slider>
        <v-slider
          v-model="settings.ALTERNATIVE_ANALYSIS.simulation_years_each"
          :label="`Each simulation year should account for ${settings.ALTERNATIVE_ANALYSIS.simulation_years_each} years`"
          :hint="schema.ALTERNATIVE_ANALYSIS.properties.simulation_years_each.tooltip"
          :error-messages="
            $utils.getIfExists(errors, [
              'settings',
              'ALTERNATIVE_ANALYSIS',
              'simulation_years_each',
              'messages',
            ])
          "
          min="1"
          max="20"
          always-dirty
          thumb-label="always"
          :class="`my-${inputMargin}`"
          @change="$emit('change')"
        ></v-slider>
        <v-switch
          label="Keep previous outputs when case is saved"
          v-model="settings.ALTERNATIVE_ANALYSIS.saveOutputs"
          :error-messages="
            $utils.getIfExists(errors, [
              'settings',
              'ALTERNATIVE_ANALYSIS',
              'saveOutputs',
              'messages',
            ])
          "
          hint="WARNING: Enabling this can cause discrepancies between the stored input and the resulting outputs"
          persistent-hint
          :class="`my-${inputMargin}`"
          @change="$emit('change')"
        ></v-switch>
      </CardSimple>
    </v-col>
    <v-col cols="12" lg="8">
      <CardSimple title="Production Cost">
        <v-switch
          label="Enable MIP"
          hint="Binary Variables"
          persistent-hint
          v-model="settings.PRODUCTION_COST.integer"
          :error-messages="
            $utils.getIfExists(errors, ['settings', 'PRODUCTION_COST', 'integer', 'messages'])
          "
          :class="`my-${inputMargin}`"
          @change="$emit('change')"
        ></v-switch>
        <v-switch
          v-model="settings.PRODUCTION_COST.output_formulation"
          :label="schema.PRODUCTION_COST.properties.output_formulation.title"
          :hint="schema.PRODUCTION_COST.properties.output_formulation.tooltip"
          persistent-hint
          :error-messages="
            $utils.getIfExists(errors, [
              'settings',
              'PRODUCTION_COST',
              'output_formulation',
              'messages',
            ])
          "
          :class="`my-${inputMargin}`"
          @change="$emit('change')"
        ></v-switch>
        <v-switch
          :label="settings.PRODUCTION_COST.subhourly ? 'Calculate Subhourly' : 'Calculate Hourly'"
          v-model="settings.PRODUCTION_COST.subhourly"
          :error-messages="
            $utils.getIfExists(errors, ['settings', 'PRODUCTION_COST', 'subhourly', 'messages'])
          "
          :class="`my-${inputMargin}`"
          @change="$emit('change')"
        ></v-switch>
        <v-slider
          v-model.number="settings.PRODUCTION_COST.tolerance"
          :label="`Tolerance: 1e${settings.PRODUCTION_COST.tolerance}`"
          :hint="schema.PRODUCTION_COST.properties.tolerance.tooltip"
          :error-messages="
            $utils.getIfExists(errors, ['settings', 'PRODUCTION_COST', 'tolerance', 'messages'])
          "
          max="-1"
          min="-38"
          always-dirty
          thumb-label="always"
          :class="`my-${inputMargin}`"
          @change="$emit('change')"
        ></v-slider>
        <v-text-field
          v-model.number="settings.PRODUCTION_COST.timeout"
          :label="schema.PRODUCTION_COST.properties.timeout.title"
          :hint="schema.PRODUCTION_COST.properties.timeout.tooltip"
          :error-messages="
            $utils.getIfExists(errors, ['settings', 'PRODUCTION_COST', 'timeout', 'messages'])
          "
          suffix="seconds"
          type="number"
          :class="`my-${inputMargin}`"
          @change="$emit('change')"
        ></v-text-field>
        <v-select
          v-model="settings.PRODUCTION_COST.capacity_source"
          :label="schema.PRODUCTION_COST.properties.capacity_source.title"
          :hint="schema.PRODUCTION_COST.properties.capacity_source.tooltip"
          :error-messages="
            $utils.getIfExists(errors, [
              'settings',
              'PRODUCTION_COST',
              'capacity_source',
              'messages',
            ])
          "
          :items="['AA', 'Inputs']"
          :class="`my-${inputMargin}`"
          @change="$emit('change')"
        ></v-select>
        <v-switch
          label="Keep previous outputs when case is saved"
          v-model="settings.PRODUCTION_COST.saveOutputs"
          :error-messages="
            $utils.getIfExists(errors, ['settings', 'PRODUCTION_COST', 'saveOutputs', 'messages'])
          "
          hint="WARNING: Enabling this can cause discrepancies between the stored input and the resulting outputs"
          persistent-hint
          :class="`my-${inputMargin}`"
          @change="$emit('change')"
        ></v-switch>
      </CardSimple>
    </v-col>
    <v-col cols="12" lg="8">
      <CardSimple title="Stacked Services Emulator">
        <v-switch
          label="Enable MIP"
          hint="Binary Variables"
          persistent-hint
          v-model="settings.STACKED_SERVICES_EMULATOR.integer"
          :error-messages="
            $utils.getIfExists(errors, [
              'settings',
              'STACKED_SERVICES_EMULATOR',
              'integer',
              'messages',
            ])
          "
          :class="`my-${inputMargin}`"
          @change="$emit('change')"
        ></v-switch>
        <v-switch
          v-model="settings.STACKED_SERVICES_EMULATOR.output_formulation"
          :label="schema.STACKED_SERVICES_EMULATOR.properties.output_formulation.title"
          :hint="schema.STACKED_SERVICES_EMULATOR.properties.output_formulation.tooltip"
          persistent-hint
          :error-messages="
            $utils.getIfExists(errors, [
              'settings',
              'STACKED_SERVICES_EMULATOR',
              'output_formulation',
              'messages',
            ])
          "
          :class="`my-${inputMargin}`"
          @change="$emit('change')"
        ></v-switch>
        <v-slider
          v-model.number="settings.STACKED_SERVICES_EMULATOR.tolerance"
          :label="`Tolerance: 1e${settings.STACKED_SERVICES_EMULATOR.tolerance}`"
          :hint="schema.STACKED_SERVICES_EMULATOR.properties.tolerance.tooltip"
          :error-messages="
            $utils.getIfExists(errors, [
              'settings',
              'STACKED_SERVICES_EMULATOR',
              'tolerance',
              'messages',
            ])
          "
          max="-1"
          min="-38"
          always-dirty
          thumb-label="always"
          :class="`my-${inputMargin}`"
          @change="$emit('change')"
        ></v-slider>
        <v-text-field
          v-model.number="settings.STACKED_SERVICES_EMULATOR.timeout"
          :label="schema.STACKED_SERVICES_EMULATOR.properties.timeout.title"
          :hint="schema.STACKED_SERVICES_EMULATOR.properties.timeout.tooltip"
          :error-messages="
            $utils.getIfExists(errors, [
              'settings',
              'STACKED_SERVICES_EMULATOR',
              'timeout',
              'messages',
            ])
          "
          suffix="seconds"
          type="number"
          :class="`my-${inputMargin}`"
          @change="$emit('change')"
        ></v-text-field>
        <InputPrice
          v-model="settings.STACKED_SERVICES_EMULATOR.price_source"
          :label="schema.STACKED_SERVICES_EMULATOR.properties.price_source.properties.type.title"
          :hint="schema.STACKED_SERVICES_EMULATOR.properties.price_source.properties.type.tooltip"
          :error-messages="
            $utils.getIfExists(errors, [
              'settings',
              'STACKED_SERVICES_EMULATOR',
              'price_source',
              'messages',
            ])
          "
          :num_years="num_years"
          :reserves="input.reserves"
          :sequencing="{
            active: sequencingIsActive,
            allowedSources: ['Market', 'Custom'],
            allowedSchedules: ['DA'],
          }"
          multi-year
          node-list
          :class="`my-${inputMargin}`"
          @change="$emit('change')"
        ></InputPrice>
        <InputToggleParam
          v-model="settings.STACKED_SERVICES_EMULATOR.sequencing"
          :error-messages="
            $utils.getIfExists(errors, [
              'settings',
              'STACKED_SERVICES_EMULATOR',
              'sequencing',
              'messages',
            ])
          "
          label="Sequencing"
          @change="$emit('change')"
        >
          <template v-slot="props">
            <InputPrice
              v-model="props.param"
              :num_years="num_years"
              :reserves="input.reserves"
              :sequencing="{
                active: !props.disabled,
                allowedSources: ['Market', 'Custom'],
                allowedSchedules: ['RT'],
              }"
              multi-year
              node-list
              @change="$emit('change')"
            ></InputPrice>
          </template>
        </InputToggleParam>
        <v-switch
          label="Keep previous outputs when case is saved"
          v-model="settings.STACKED_SERVICES_EMULATOR.saveOutputs"
          hint="WARNING: Enabling this can cause discrepancies between the stored input and the resulting outputs"
          persistent-hint
          @change="$emit('change')"
        ></v-switch>
      </CardSimple>
    </v-col>
  </v-row>
</template>

<script>
import { schemas } from "data-dictionary";

export default {
  name: "SystemSettings",
  components: {
    CardSimple: () => import("@/components/CardSimple.vue"),
    InputPrice: () => import("@/components/InputPrice.vue"),
    InputToggleParam: () => import("@/components/InputToggleParam"),
    InputDateArray: () => import("@/components/InputDateArray"),
    // LabelTooltip: () => import('@/components/LabelTooltip'),
  },
  props: ["input", "settings", "num_years", "errors"],
  methods: {},
  data() {
    return {
      inputMargin: 4,
      schema: schemas.filter((v) => v.$id === "settings.schema.json")[0].properties,
    };
  },
  computed: {
    sequencingIsActive() {
      return (
        this.settings.STACKED_SERVICES_EMULATOR.sequencing &&
        this.settings.STACKED_SERVICES_EMULATOR.sequencing.active
      );
    },
  },
  created() {
    if (!Array.isArray(this.settings.ALTERNATIVE_ANALYSIS.chosen_days)) {
      this.settings.ALTERNATIVE_ANALYSIS.chosen_days = [];
    }
  },
};
</script>

<style></style>
