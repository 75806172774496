<template>
  <v-row justify="center">
    <v-col cols="12" lg="8">
      <CardSimple title="Demand Parameters">
        <InputYearly
          v-model="input.demand_peak"
          :error-messages="$utils.getIfExists(errors, ['input', 'demand_peak'])"
          :label="schema.demand_peak.title"
          :hint="schema.demand_peak.tooltip"
          :suffix="schema.demand_peak.unit"
          type="number"
          :num_years="num_years"
          fill
          :fill-value="schema.demand_peak.items.default"
          @change="$emit('change')"
        ></InputYearly>
        <InputYearly
          v-model="input.demand_annual"
          :error-messages="$utils.getIfExists(errors, ['input', 'demand_annual'])"
          :label="schema.demand_annual.title"
          :hint="schema.demand_annual.tooltip"
          :suffix="schema.demand_annual.unit"
          type="number"
          :num_years="num_years"
          fill
          :fill-value="schema.demand_annual.items.default"
          @change="$emit('change')"
        ></InputYearly>
        <InputYearlyProfile
          v-model="input.demand_profile"
          :title="schema.demand_profile.title"
          :hint="schema.demand_profile.tooltip"
          :error-messages="$utils.getIfExists(errors, ['input', 'demand_profile'])"
          :num_years="num_years"
          profile-type="DEMAND"
          :scaleProfile="
            (data, year) =>
              scaleDemandProfile(input.demand_annual[year], input.demand_peak[year], data)
          "
          fill
          :fill-value="input.demand_profile[0] || null"
          @change="$emit('change')"
        >
        </InputYearlyProfile>
      </CardSimple>
    </v-col>
    <v-col cols="12" lg="8">
      <SystemDemandResponse
        :demand_response.sync="input.demand_response"
        :error-messages="$utils.getIfExists(errors, ['input', 'demand_response'])"
        :reserves="input.reserves"
        :num_years="num_years"
        @change="$emit('change')"
      ></SystemDemandResponse>
    </v-col>
    <v-col cols="12" lg="8">
      <v-expansion-panels popout>
        <v-expansion-panel class="elevation-1">
          <v-expansion-panel-header>
            <div class="title">Capacity of All Units</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ChartStacked
              type="line"
              :items="[...input.generators, ...input.storage]"
              yAxisLabel="Capacity (MW)"
              valueKey="capacity"
              :num_years="num_years"
            ></ChartStacked>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { schemas } from "data-dictionary";

export default {
  name: "SystemDemand",
  components: {
    SystemDemandResponse: () => import("@/components/SystemDemandResponse.vue"),
    CardSimple: () => import("@/components/CardSimple.vue"),
    InputYearly: () => import("@/components/InputYearly.vue"),
    ChartStacked: () => import("@/components/charts/ChartStacked.vue"),
    InputYearlyProfile: () => import("@/components/InputYearlyProfile.vue"),
  },
  props: ["num_years", "input", "settings", "errors"],
  computed: {
    ...mapGetters({
      getProfile: "profiles/get",
    }),
  },
  data() {
    return {
      schema: schemas.filter((v) => v.$id === "input.schema.json")[0].properties,
      demandChartOptions: {
        responsive: true,
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Hour",
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Demand (MW)",
              },
            },
          ],
        },
        zoom: {
          enabled: false,
          mode: "xy",
        },
        pan: {
          enabled: false,
          mode: "xy",
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
      },
    };
  },
  methods: {
    scaleDemandProfile(energyDemand, peakDemand, profile) {
      if (profile) {
        const maxProfile = Math.max(...profile);
        const numPeaks = profile.filter((e) => e === maxProfile).length;
        const energy = profile.reduce((a, e) => a + e);
        const scale =
          (energyDemand * 1000 - peakDemand * numPeaks) / (energy - maxProfile * numPeaks);
        const profileOutput = profile.map((val) => (val === maxProfile ? peakDemand : scale * val));
        return profileOutput;
      }
      return [];
    },
  },
};
</script>

<style></style>
