<template>
  <v-card :color="color" class="ma-3" v-bind="$attrs">
    <v-card-title v-if="title">
      <h3 class="title">{{ title }}</h3>
    </v-card-title>
    <v-card-text v-if="hasSlot" :class="textClass">
      <slot></slot>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "CardSimple",
  props: {
    color: String,
    title: String,
    textClass: [String, Object],
  },
  computed: {
    hasSlot() {
      return !!this.$slots.default;
    },
  },
};
</script>
