<template>
  <v-row justify="center">
    <v-col cols="12" lg="8">
      <CardSimple title="Renewables">
        <InputYearly
          v-model="input.minimum_renewable"
          :error-messages="$utils.getIfExists(errors, ['input', 'minimum_renewable'])"
          :label="schema.minimum_renewable.title"
          :hint="schema.minimum_renewable.tooltip"
          suffix="%"
          type="number"
          :num_years="num_years"
          fill
          :fill-value="schema.minimum_renewable.items.default"
          @change="$emit('change')"
        ></InputYearly>
      </CardSimple>
    </v-col>
    <v-col cols="12" lg="8">
      <CardSimple title="Emission Programs">
        <InputYearly
          v-model="input.co2_price"
          :error-messages="$utils.getIfExists(errors, ['input', 'co2_price'])"
          :label="schema.co2_price.title"
          :hint="schema.co2_price.tooltip"
          :suffix="schema.co2_price.unit"
          type="number"
          :num_years="num_years"
          fill
          :fill-value="schema.co2_price.items.default"
          @change="$emit('change')"
        ></InputYearly>
      </CardSimple>
    </v-col>
    <v-col cols="12" lg="8">
      <CardSimple title="Energy Efficiency">
        <InputYearly
          v-model="input.efficient_power_capacity"
          :error-messages="$utils.getIfExists(errors, ['input', 'efficient_power_capacity'])"
          :label="schema.efficient_power_capacity.title"
          :hint="schema.efficient_power_capacity.tooltip"
          :suffix="schema.efficient_power_capacity.unit"
          type="number"
          :num_years="num_years"
          fill
          :fill-value="schema.efficient_power_capacity.items.default"
          @change="$emit('change')"
        ></InputYearly>
        <InputYearly
          v-model="input.efficient_investment_cost"
          :error-messages="$utils.getIfExists(errors, ['input', 'efficient_investment_cost'])"
          :label="schema.efficient_investment_cost.title"
          :hint="schema.efficient_investment_cost.tooltip"
          :suffix="schema.efficient_investment_cost.unit"
          type="number"
          :num_years="num_years"
          fill
          :fill-value="schema.efficient_investment_cost.items.default"
          @change="$emit('change')"
        ></InputYearly>
        <InputYearly
          v-model="input.efficient_maximum_capacity"
          :error-messages="$utils.getIfExists(errors, ['input', 'efficient_maximum_capacity'])"
          :label="schema.efficient_maximum_capacity.title"
          :hint="schema.efficient_maximum_capacity.tooltip"
          :suffix="schema.efficient_maximum_capacity.unit"
          type="number"
          :num_years="num_years"
          fill
          :fill-value="schema.efficient_maximum_capacity.items.default"
          @change="$emit('change')"
        ></InputYearly>
        <InputYearly
          v-model="input.efficient_maximum_build"
          :error-messages="$utils.getIfExists(errors, ['input', 'efficient_maximum_build'])"
          :label="schema.efficient_maximum_build.title"
          :hint="schema.efficient_maximum_build.tooltip"
          :suffix="schema.efficient_maximum_build.unit"
          type="number"
          :num_years="num_years"
          fill
          :fill-value="schema.efficient_maximum_build.items.default"
          @change="$emit('change')"
        ></InputYearly>
        <v-text-field
          v-model.number="input.energy_savings"
          :error-messages="$utils.getIfExists(errors, ['input', 'energy_savings', 'messages'])"
          :label="schema.energy_savings.title"
          :hint="schema.energy_savings.tooltip"
          :suffix="schema.energy_savings.unit"
          type="number"
          @change="$emit('change')"
        ></v-text-field>
      </CardSimple>
    </v-col>
    <v-col cols="12" lg="8">
      <CardSimple title="Financial Parameters">
        <v-text-field
          v-model.number="input.discount"
          :error-messages="$utils.getIfExists(errors, ['input', 'discount', 'messages'])"
          :label="schema.discount.title"
          :hint="schema.discount.tooltip"
          :suffix="schema.discount.unit"
          type="number"
          @change="$emit('change')"
        ></v-text-field>
        <v-text-field
          v-model.number="input.target_LCOE"
          :error-messages="$utils.getIfExists(errors, ['input', 'target_LCOE', 'messages'])"
          label="Target Joint Resource LCOE"
          suffix="$/kWh"
          type="number"
          @change="$emit('change')"
        ></v-text-field>
      </CardSimple>
    </v-col>
  </v-row>
</template>

<script>
import { schemas } from "data-dictionary";
import CardSimple from "@/components/CardSimple";
import InputYearly from "@/components/InputYearly";

export default {
  name: "SystemPrograms",
  components: {
    CardSimple,
    InputYearly,
  },
  props: ["input", "num_years", "errors"],
  data() {
    return {
      schema: schemas.filter((v) => v.$id === "input.schema.json")[0].properties,
    };
  },
  computed: {},
};
</script>

<style></style>
