<template>
  <v-card>
    <v-card-text
      ><v-row class="justify-center"
        ><v-col sm="6" class="mt-6"
          ><v-textarea v-model="textValue" label="Paste your values..." dense />
        </v-col>
        <v-col sm="6">
          <v-subheader>Preview</v-subheader>
          <v-simple-table dense
            ><tbody>
              <tr v-for="(row, rIndex) in tableValue" :key="rIndex">
                <span v-if="Array.isArray(row)">
                  <td v-for="(col, cIndex) in row" :key="cIndex">
                    {{ col }}
                  </td>
                </span>
                <td v-else>{{ row }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row></v-card-text
    >
    <v-card-actions
      ><v-spacer />
      <v-btn text color="primary" @click="cancel()">Cancel</v-btn>
      <v-btn text color="primary" @click="save()">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { deepClone } from "@/plugins/utils";

export default {
  name: "InputTable",
  components: {},
  mixins: [],
  props: {
    value: { type: Array, required: true },
    isDialogOpen: { type: Boolean, required: true },
    num_years: { type: Number, required: false, default: 2 },
  },
  data() {
    return {
      originalValue: undefined,
    };
  },
  computed: {
    textValue: {
      get() {
        return this.arrayToString(this.value);
      },
      set(val) {
        this.value.splice(
          0,
          this.num_years,
          ...val
            .split("\n")
            .slice(0, this.num_years)
            .map((v) => Number(v))
        );

        this.$emit("input", this.value);
      },
    },
    tableValue() {
      const defaultArray = [...Array(this.num_years).keys()].map((v) => [`Year ${v + 1}`, " "]);
      if (this.textValue) {
        const parsedPasted = this.textValue.split("\n").map((v) => v.split("	"));
        return defaultArray.map((v, i) => [
          v[0],
          ...(i < parsedPasted.length ? parsedPasted[i] : []),
        ]);
      }
      return defaultArray;
    },
  },
  watch: {
    isDialogOpen(val) {
      if (val) {
        this.originalValue = deepClone(this.value);
      }
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    this.originalValue = deepClone(this.value);
  },
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    arrayToString(array) {
      return array.slice(0, this.num_years).toString().replace(/,/g, "\n");
    },
    cancel() {
      this.textValue = this.arrayToString(this.originalValue);
      this.$emit("close-dialog");
    },
    save() {
      this.$emit("close-dialog");
    },
  },
};
</script>

<style></style>
