<template>
  <v-container fluid class="pa-0">
    <v-navigation-drawer mobile-breakpoint="600" app clipped v-model="navDrawer">
      <v-list expand dense>
        <v-list-item>
          <v-list-item-content>
            <SelectScenario
              v-model="scenario_id"
              flat
              hide-details
              hide-create
              @input="gotoScenario"
            ></SelectScenario>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="mb-2">
          <v-list-item-content>
            <SelectCase
              v-model="case_id"
              :scenario_id="scenario_id"
              flat
              hide-details
              hide-create
              @save-as="saveCaseAs"
            ></SelectCase>
          </v-list-item-content>
        </v-list-item>
        <v-list-group no-action :value="true">
          <template #activator>
            <v-list-item-content>
              <v-list-item-title>Inputs</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            v-for="page in inputPages"
            :key="page.name"
            :to="{ name: 'CaseInput', hash: hashify(page.name) }"
            class="pl-8"
          >
            <v-list-item-content>
              <v-list-item-title>{{ page.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item :to="{ name: 'CaseInput', hash: '#Settings' }">
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item>
        <!-- <v-list-item>
          <v-list-item-title>Calculations</v-list-item-title>
        </v-list-item>-->
        <v-list-item
          to="output"
          :disabled="!jobs.length || jobs.length == getJobsInProgress.length"
        >
          <v-list-item-title>Outputs</v-list-item-title>
        </v-list-item>
        <v-list-group no-action :value="true">
          <template #activator>
            <v-list-item-title>Calculations</v-list-item-title>
            <v-list-item-action v-if="jobLoadPending && !isJobInProgress" class="my-0">
              <v-progress-circular indeterminate color="secondary"></v-progress-circular>
            </v-list-item-action>
            <v-list-item-action v-else-if="isJobInProgress" class="my-0">
              <v-tooltip top>
                <template #activator="{ on }">
                  <span class="caption">Stop Jobs</span>
                  <v-btn icon @click.stop="cancelJobsInProgress()" v-on="on">
                    <v-icon>cancel</v-icon>
                  </v-btn>
                </template>
                <span>Stop Jobs In Progress</span>
              </v-tooltip>
            </v-list-item-action>
            <!-- <v-list-item-action v-else @click="loadJobs">
            <v-icon>refresh</v-icon>
            </v-list-item-action>-->
          </template>

          <template v-if="currentCase">
            <v-list-item
              v-for="page in calculations"
              :key="page.name"
              :disabled="!jobStartable(page) || jobLoadPending"
              class="pl-8"
              @click="runJob(page.type)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ page.name }}</v-list-item-title>
                <v-list-item-subtitle v-if="getJob(page.type)">{{
                  new Date(getJob(page.type).created).toLocaleString()
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action class="my-0">
                <JobIndicator
                  :case="currentCase"
                  :job="page.job"
                  :jobType="page.type"
                  prevent-action
                >
                </JobIndicator>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list-group>
        <MessageList
          v-if="currentCase"
          :currentCase="currentCase"
          :jobInProgress="isJobInProgress"
        ></MessageList>
      </v-list>
    </v-navigation-drawer>

    <keep-alive>
      <transition>
        <router-view
          :currentCase="currentCase"
          :errors="currentCaseErrors"
          :num_years="num_years"
          :hasChanged="hasChanged"
          @toggle-drawer="navDrawer = !navDrawer"
          @change="caseChanged"
          @save="confirmSaveCase"
          @remove="removeCase"
        ></router-view>
      </transition>
    </keep-alive>

    <v-dialog v-model="reRunDialog" max-width="600">
      <v-card>
        <v-card-title class="title warning mb-4"> <v-icon>warning</v-icon>Warning! </v-card-title>
        <v-card-text class="body-1">
          {{
            `You will delete current outputs! Are you sure you want to run ${mapTypeName(
              currentType
            )}
          again?`
          }}
        </v-card-text>
        <v-card-text class="body-1">{{
          currentType === 1 && aaAndPCComplete ? "Production Cost Outputs will be invalid." : ""
        }}</v-card-text>
        <v-card-actions>
          <v-btn @click="reRunDialog = false" text color="red darken-1"> Cancel </v-btn>
          <v-spacer />
          <v-btn color="primary" text @click="reRunJob(currentType)">
            {{ "Run " + `${mapTypeName(currentType)}` }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="invalidCaseDialog" max-width="600">
      <v-card>
        <v-alert type="error" tile> Error! </v-alert>
        <v-card-text class="pt-2">
          {{ invalidCaseDialogProps.errorMsg }}
          <v-list dense>
            <v-list-item>
              <v-list-item-title> Errors (total: {{ caseErrors.length }}): </v-list-item-title>
            </v-list-item>
            <v-list-item v-for="(error, i) in dialogErrors" :key="i" class="error-item">
              <v-list-item-subtitle>
                {{ parseError(error) }}
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item v-if="caseErrors.length > 5">
              <v-tooltip top>
                <template #activator="{ on }">
                  <p
                    class="text-clickable caption"
                    v-on="on"
                    @click="showAllErrors = !showAllErrors"
                  >
                    {{ showAllErrors ? "Collapse" : "..." }}
                  </p>
                </template>
                <span>{{ showAllErrors ? "Collapse items" : "Show all" }}</span>
              </v-tooltip>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="invalidCaseDialog = false"> Nevermind </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="confirmInvalidCase(invalidCaseDialogProps.mode)"
            :loading="caseSavePending"
          >
            {{ invalidCaseDialogProps.saveBtn }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmSaveDialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline warning"> <v-icon>warning</v-icon>Warning! </v-card-title>
        <v-card-text>
          This case has outputs that will be deleted if you save this case.
          <br />Would you like to save your changes as a new case?
          <v-text-field v-model="newCaseName" label="New Case" type="text" hide-details>
            <template #append-outer>
              <v-btn @click="saveCaseAs(newCaseName)">Save As</v-btn>
            </template>
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="confirmSaveDialog = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="saveCase" :loading="caseSavePending">
            Save Anyways
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { makeFindMixin } from "feathers-vuex";
import { mapState, mapGetters, mapActions } from "vuex";
import { schemas, CaseModel } from "data-dictionary";
import Ajv from "ajv";
import profilesMixin from "@/mixins/profiles";

export default {
  name: "CaseView",
  components: {
    SelectScenario: () => import("@/components/SelectScenario.vue"),
    SelectCase: () => import("@/components/SelectCase.vue"),
    JobIndicator: () => import("@/components/JobIndicator.vue"),
    MessageList: () => import("@/components/MessageList.vue"),
  },
  mixins: [
    profilesMixin,
    makeFindMixin({
      service: "jobs",
      watch: true,
    }),
  ],
  props: {
    scenario: {
      type: Number,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      ajv: new Ajv({
        schemas,
        allErrors: true,
        coerceTypes: true,
        jsonPointers: true,
      }),

      currentCase: null,
      hasChanged: false,
      currentType: null,
      reRunDialog: null,
      confirmSaveDialog: null,
      invalidCaseDialog: null,
      invalidCaseDialogProps: {
        mode: "save",
        errorMsg: "This case has errors! Are you sure you want to save?",
        saveBtn: "Save Anyways",
      },
      newCaseName: "",

      caseErrors: [],

      outputPoll: null,

      navDrawer: null,
      navDrawerInput: true,
      navDrawerCalculations: true,
      pages: [
        {
          name: "Settings",
        },
        {
          name: "Programs",
        },
        {
          name: "Reliability Criteria",
        },
        {
          name: "Demand",
        },
        {
          name: "Generators",
        },
        {
          name: "Energy Storage",
        },
        {
          name: "Market",
        },
      ],
      showAllErrors: false,
    };
  },

  computed: {
    ...mapState({}),
    ...mapGetters({
      getCase: "cases/get",
      caseSavePending: "cases/savePending",
      jobLoadPending: "jobs/loadPending",
    }),
    jobsParams() {
      return {
        paginate: false,
        query: { case_id: this.case_id },
      };
    },
    calculations() {
      return [
        {
          name: "Alternative Analysis",
          value: "ALTERNATIVE_ANALYSIS",
          type: 1,
          job: this.getJob(1),
        },
        {
          name: "Production Cost",
          value: "PRODUCTION_COST",
          type: 2,
          job: this.getJob(2),
        },
        {
          name: "Stacked Services Emulator",
          value: "STACKED_SERVICES_EMULATOR",
          type: 5,
          job: this.getJob(5),
        },
      ];
    },

    num_years() {
      if (this.currentCase) {
        const { year_start: startYear, year_end: endYear } = this.currentCase.settings;
        return endYear - startYear + 1;
      }
      return null;
    },
    scenario_id: {
      get() {
        return this.scenario;
      },
      set(s) {
        this.$router.push({
          name: this.$route.name,
          params: { scenario: s, id: this.case_id },
          hash: this.$route.hash,
        });
      },
    },
    case_id: {
      get() {
        return this.id;
      },
      set(c) {
        this.$router.push({
          name: this.$route.name,
          params: { scenario: this.scenario_id, id: c },
          hash: this.$route.hash,
        });
      },
    },
    inputPages() {
      const inputs = Object.values(this.pages);
      return inputs.filter((e) => e.name !== "Settings");
    },
    getJobsInProgress() {
      if (!this.jobs.length) return [];
      return this.jobs.filter((e) => e.status === "IN_PROGRESS" || e.status === "NOT_STARTED");
    },
    isJobInProgress() {
      return this.jobs && !!this.getJobsInProgress.length;
    },
    validate() {
      return this.ajv.getSchema("case.schema.json");
    },
    currentCaseErrors() {
      const errors = this.caseErrors.reduce((acc, err) => {
        const path = err.dataPath.substring(1).split("/");
        this.$utils.pathBuilder(acc, path, err.message);
        return acc;
      }, {});

      return errors;
    },
    aaAndPCComplete() {
      const aaJob = this.getJob(1);
      const pcJob = this.getJob(2);
      if (aaJob && pcJob && aaJob.status === "COMPLETE" && pcJob.status === "COMPLETE") {
        return true;
      }
      return false;
    },
    dialogErrors() {
      if (this.showAllErrors) {
        return this.caseErrors;
      }
      return this.caseErrors.length > 5 ? this.caseErrors.slice(0, 5) : this.caseErrors;
    },
  },
  watch: {
    $route(to, from) {
      if (from.params.scenario !== to.params.scenario || from.params.id !== to.params.id) {
        this.init();
      }
    },
  },
  created() {
    this.init();
  },
  beforeDestroy() {
    clearInterval(this.outputPoll);
  },
  methods: {
    ...mapActions({
      loadCase: "cases/get",
      createJob: "jobs/create",
    }),
    init() {
      this.$store.commit("scenarios/setCurrent", this.scenario_id);
      this.loadCase(this.case_id)
        .then((res) => {
          if (res.code && res.code === "404") {
            return this.gotoScenario(this.scenario_id);
          }
          this.initCurrentCase();
          return this.loadJobs();
        })
        .catch(() => {
          this.gotoScenario(this.scenario_id);
        });
    },
    initCurrentCase() {
      this.currentCase = this.getCase(this.case_id).clone();
      console.debug("Case:", this.currentCase);
    },
    async confirmSaveCase() {
      console.debug("Saving", this.currentCase);
      const validateCase = await this.validateCase();
      if (!validateCase && !this.invalidCaseDialog) {
        this.invalidCaseDialogProps = {
          mode: "save",
          errorMsg: "This case has errors! Are you sure you want to save?",
          saveBtn: "Save Anyways",
        };
        this.invalidCaseDialog = true;
        return;
      }

      this.invalidCaseDialog = false;
      if (this.jobs.length) {
        this.confirmSaveDialog = true;
      } else {
        this.saveCase();
      }
    },
    async validateCase() {
      const validateCase = new CaseModel(this.currentCase);
      const profileErrors = await this.validateProfiles(validateCase);
      if (!this.validate(validateCase) || profileErrors.length) {
        this.caseErrors = [
          ...(!this.validate(validateCase) ? this.validate.errors : []),
          ...profileErrors,
        ];
        console.error("This case has errors: ", this.caseErrors, this.currentCaseErrors);
        return false;
      }
      this.caseErrors = [];
      return true;
    },
    caseChanged() {
      this.hasChanged = true;
      this.populateProfiles();
      this.validateCase();
    },
    saveCase() {
      this.currentCase.commit();
      this.currentCase.save().then(() => {
        this.confirmSaveDialog = false;
        this.invalidCaseDialog = false;
        this.hasChanged = false;
        this.initCurrentCase();
        this.loadJobs();
      });
    },
    saveCaseAs(name) {
      this.confirmSaveDialog = false;
      delete this.currentCase.id;
      delete this.currentCase.created;
      delete this.currentCase.updated;
      delete this.currentCase.jobs;

      this.currentCase.name = name;
      this.currentCase
        .save()
        .then((c) => {
          this.case_id = c.id;
          return c;
        })
        .then((_case) => {
          this.$store.dispatch("scenarios/get", _case.scenario_id);
        });
    },
    removeCase() {
      this.$store.dispatch("cases/confirmRemove", this.case_id).then((res) => {
        if (res) {
          this.gotoScenario(this.scenario_id);
        }
      });
    },
    hashify(str) {
      return `#${str.replace(/ /g, "")}`;
    },
    async runJob(type) {
      console.debug("Run", this.mapTypeName(type));
      const validateCase = await this.validateCase();
      if (!validateCase && !this.invalidCaseDialog) {
        this.invalidCaseDialogProps = {
          mode: "run",
          errorMsg: `This case has errors! Are you sure you want to run ${this.mapTypeName(type)}?`,
          saveBtn: "Run Anyways",
          jobType: type,
        };
        this.invalidCaseDialog = true;
        return;
      }

      this.invalidCaseDialog = false;
      this.currentType = type;
      const currentJob = this.getJob(type);
      if (currentJob && currentJob.status === "COMPLETE") {
        this.reRunDialog = true;
      } else {
        this.newJob(type);
      }
    },
    reRunJob(type, optType = null) {
      this.newJob(type);
      if (optType) {
        this.newJob(optType);
      }
      this.reRunDialog = false;
    },
    removeOldJob(type) {
      const oldJob = this.getJob(type);
      if (oldJob) {
        return this.$store.dispatch("jobs/remove", oldJob.id);
      }
      return Promise.resolve();
    },
    newJob(type) {
      this.removeOldJob(type).then(() => {
        this.createJob({
          case_id: this.case_id,
          status: "NOT_STARTED",
          type,
        }).then(() => {
          this.outputPoll = setTimeout(this.loadJobs, 2 * 1000);
        });
      });
    },
    getJob(type) {
      if (this.jobs) {
        return this.jobs.find((e) => e.type === type);
      }
      return null;
    },
    cancelJobsInProgress() {
      this.getJobsInProgress.forEach((element) => {
        this.$store.dispatch("jobs/remove", element.id);
      });
      this.loadJobs();
    },
    gotoScenario(id) {
      this.$router.push({
        name: "ScenarioCases",
        params: { id },
      });
    },
    jobStartable({ type }) {
      const job = this.getJob(type);
      if (job) {
        return ["NOT_STARTED", "ERROR", "COMPLETE"].includes(job.status);
      }
      return true;
    },
    loadJobs() {
      console.debug("Loading Jobs");
      return this.$store
        .dispatch("jobs/find", {
          paginate: false,
          query: { case_id: this.case_id },
        })
        .then((res) => {
          console.debug("Jobs Found", res);
          if (!res.total) {
            this.$store.commit("jobs/clearAll");
          } else if (this.isJobInProgress) {
            // 15 seconds
            setTimeout(this.loadJobs, 10 * 1000);
          }
        });
    },
    mapTypeName(type, short = null) {
      const shortName = { 1: "AA", 2: "PC", 5: "SSE" };
      const longName = {
        1: "Alternative Analysis",
        2: "Production Cost",
        5: "Stacked Services Emulator ",
      };
      return short ? shortName[type] : longName[type];
    },
    parseError(error) {
      return `${error.dataPath.substring(1).replace(/\//g, " > ").replace(/_/g, " ")}: ${
        error.message
      }`;
    },
    confirmInvalidCase(mode) {
      if (mode === "save") {
        this.confirmSaveCase();
      } else if (mode === "run") {
        this.runJob(this.invalidCaseDialogProps.jobType);
      }
    },
  },
};
</script>

<style scoped>
.error-item {
  min-height: 24px;
}
.text-clickable {
  cursor: pointer;
}
.text-clickable:hover {
  cursor: pointer;
  color: black;
}
</style>
