<template>
  <Plotly
    :data="data"
    :layout="layout"
    :display-mode-bar="true"
    :displaylogo="false"
    :mode-bar-buttons-to-remove="[
      'toggleSpikelines',
      'hoverClosestCartesian',
      'hoverCompareCartesian',
      'select2d',
      'lasso2d',
      'toImage',
    ]"
  />
</template>

<script>
import { Plotly } from "vue-plotly";

export default {
  name: "Chart",
  components: { Plotly },
  mixins: [],
  props: {
    data: {
      type: Array,
      required: true,
    },
    layout: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {},
};
</script>

<style></style>
